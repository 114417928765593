import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Switch, Dimensions, Alert, Modal, TouchableOpacity as TouchButton, TouchableOpacity, TextInput, Platform, FlatList, Image } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather, AntDesign } from '@expo/vector-icons';
import RNPickerSelect from 'react-native-picker-select';
import PhoneInput from "react-native-phone-number-input";
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Moment from 'moment';
import 'moment/locale/it';
import DateTimePicker from '@react-native-community/datetimepicker';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class StudiScreen extends React.Component {
    constructor(props) {
        super(props)
        this.searchInput = React.createRef()
        this.state = {
            loading: true,
            loadingData: false,
            listStudi: [],
            storyStudi: [],
            showModal: false,
            cerca: '',
            searchResult: [],
            cercaStudio: '',
            loadingSearch: false,
            page: 1,
            isLoading: false,
            loadingFinish: false,
            isFiltri: false,
            image: '',
            nomeStudio: '',
            via: '',
            citta: '',
            cap: '',
            descrizione: '',
            idStudio: '',
            loadingActive: false,
            isEdit: false,
            widthTitle: 0
        }
    }

    componentDidMount() {
        this.checkAuth()
        this.getStudi()
    }

    checkAuth = async () => {
        const id = await AsyncStorage.getItem('id')
        if (id != null && id != undefined && id != '') {

        } else {
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'Login' }],
            })
        }
    }

    getStudi = async () => {
        let listStudi = this.state.listStudi;
        const id = await AsyncStorage.getItem('id')
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', this.state.page)
        axios.post('https://clinicautomation.it/app/get_studi.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            this.setState({ loading: false })
            if (json.length > 0) {
                listStudi = [...listStudi, ...json];
                this.setState({ listStudi: listStudi, isLoading: false })
            } else {
                this.setState({ isLoading: false, loadingFinish: true })
            }
        }).catch((error) => {
            this.setState({ loading: false, isLoading: false })
            console.error(error);
        })
    }

    searchStudi = async (search) => {
        this.setState({ cercaStudio: search, loadingSearch: true, loading: true, page: 1 })
        const id = await AsyncStorage.getItem('id')
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('search_item', search);
        formData.append('page', 1);
        axios.post('https://clinicautomation.it/app/search_studi.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            this.setState({ loadingSearch: false, loading: false, isFiltri: true })
            if (json.length > 0) {
                this.setState({ listStudi: json })
            } else {
                this.setState({ listStudi: [] })
            }
        }).catch((error) => {
            this.setState({ loadingSearch: false, loading: false })
            console.error(error);
        })
    }

    searchStudiContinue = async () => {
        let listStudi = this.state.listStudi;
        const id = await AsyncStorage.getItem('id')
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('search_item', this.state.cercaStudio);
        formData.append('page', this.state.page);
        axios.post('https://clinicautomation.it/app/search_studi.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                listStudi = [...listStudi, ...json];
                this.setState({ listStudi: listStudi, isLoading: false })
            } else {
                this.setState({ isLoading: false, loadingFinish: true })
            }
        }).catch((error) => {
            this.setState({ loading: false, isLoading: false })
            console.error(error);
        })
    }

    handleDeleteRequest = (user, index) => {
        if (confirm("Attenzione! Sei sicuro di voler eliminare la sala?") == true) {
            this.handleDelete(user, index)
        }
    }

    handleDelete = async (user, index) => {
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id', user);
        axios.post('https://clinicautomation.it/app/delete_studio.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                const listStudi = this.state.listStudi;
                listStudi.splice(index, 1)
                this.setState({ listStudi })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    handleAddStudio = async () => {
        if (this.state.nomeStudio !== '') {
            this.setState({ loadingActive: true })
            const id = await AsyncStorage.getItem('id')
            const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_user', id);
            formData.append('nome_studio', this.state.nomeStudio);
            formData.append('via', this.state.via);
            formData.append('citta', this.state.citta);
            formData.append('cap', this.state.cap);
            formData.append('image', this.state.image);
            formData.append('descrizione', this.state.descrizione);
            axios.post('https://clinicautomation.it/app/add_studio.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    this.resetListPage()
                    this.setState({ loadingActive: false, showModal: false, isEdit: false, image: '', nomeStudio: '', via: '', citta: '', cap: '', descrizione: '' })
                } else {
                    this.setState({ loadingActive: false })
                    alert(`Attenzione! ${json.message}`)
                }
            }).catch((error) => {
                this.setState({ loadingActive: false })
                console.error(error);
            })
        } else {
            alert(`Attenzione! Compila tutti i campi obbligatori`)
        }
    }

    handleEditStudio = async () => {
        if (this.state.idStudio !== '' && this.state.nomeStudio !== '') {
            this.setState({ loadingActive: true })
            const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_studio', this.state.idStudio);
            formData.append('nome_studio', this.state.nomeStudio);
            formData.append('via', this.state.via);
            formData.append('citta', this.state.citta);
            formData.append('cap', this.state.cap);
            formData.append('image', this.state.image);
            formData.append('descrizione', this.state.descrizione);
            axios.post('https://clinicautomation.it/app/edit_studio.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    this.resetListPage()
                    this.setState({ loadingActive: false, showModal: false, isEdit: false, image: '', nomeStudio: '', via: '', citta: '', cap: '', descrizione: '' })
                } else {
                    this.setState({ loadingActive: false })
                    alert(`Attenzione! ${json.message}`)
                }
            }).catch((error) => {
                this.setState({ loadingActive: false })
                console.error(error);
            })
        } else {
            alert(`Attenzione! Compila tutti i campi obbligatori`)
        }
    }

    renderItem = ({item, index}) => {
        const isEven = index % 2 === 0;
        return(
            <View key={index} style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: 10, justifyContent: 'space-between', marginBottom: 15, marginRight: (!isEven) ? 0 : '6%' }]}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {(item.image !== null && item.image !== "")
                        ?
                        <Image source={{ uri: item.image }} resizeMode='cover' style={{ width: 40, height: 40, borderRadius: 20 }} />
                        :
                        <Image source={require('../assets/icon-ios.png')} resizeMode='cover' style={{ width: 40, height: 40, borderRadius: 20 }} />
                    }
                    <View style={{ marginLeft: 15, width: (Dimensions.get('screen').width <= 500) ? '50%' : 'auto' }}>
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>{item.nome_studio}</Text>
                        {(item.descrizione !== null && item.descrizione !== "")
                            &&
                            <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>{item.descrizione}</Text>
                        }
                    </View>
                </View>
                <View>
                    <TouchableOpacity style={[styles.buttonMore, { marginBottom: 8 }]} onPress={() => this.setState({ isEdit: true, idStudio: item.id, image: (item.image !== null && item.image !== '') ? item.image : '', nomeStudio: item.nome_studio, via: item.via, citta: item.citta, cap: item.cap, descrizione: item.descrizione, showModal: true })}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Modifica</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.buttonMore, { backgroundColor: '#fc3535' }]} onPress={() => this.handleDeleteRequest(item.id, index)}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Elimina</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    renderFooter = () => {
        return(
            this.state.isLoading && !this.state.loadingFinish ?
            <View style={{ marginTop: 10, alignItems: 'center' }}>
                <ActivityIndicator size='small' color='#FFF' />
            </View> : null
        );
    }

    handleMore = () => {
        if (!this.state.isFiltri) {
            this.setState({ page: this.state.page + 1, isLoading: true })
            setTimeout(() => {
                this.getStudi()
            }, 1000);
        } else {
            this.setState({ page: this.state.page + 1, isLoading: true })
            setTimeout(() => {
                this.searchStudiContinue()
            }, 1000);
        }
    }

    resetListPage = () => {
        this.setState({ page: 1, loading: true, listStudi: [], isFiltri: false, loadingSearch: false, cercaStudio: '' })
        setTimeout(() => {
            this.getStudi()
        }, 1000);
    }

    pickImage = async () => {
        let permission = await ImagePicker.getMediaLibraryPermissionsAsync();
        if (permission.status != 'granted') {
            let request = await ImagePicker.requestMediaLibraryPermissionsAsync();
            if (request.status != 'granted') {
                alert("Per caricare un'immagine devi acconsentire il permesso per accedere alla tua galleria");
            } else {
                this.imageGallery()
            }
        } else {
            this.imageGallery()
        }
    }

    imageGallery = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [4, 4],
            quality: 0.8
        })
        if (!result.canceled) {
            const manipulateImge = await ImageManipulator.manipulateAsync(result.assets[0].uri, [{ resize: { width: 700 } }], { format: ImageManipulator.SaveFormat.PNG, base64: true })
            const newAvatarImage = `data:image/png;base64,${manipulateImge.base64}`;
            this.setState({ image: newAvatarImage })
        }
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('App'); // Schermata di fallback
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Sale</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.backButton} onPress={() => this.setState({ showModal: true, isEdit: false })}>
                        <AntDesign name='pluscircleo' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 7 }}>Aggiungi</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ marginBottom: 25, paddingTop: 20, paddingHorizontal: 20, alignItems: 'center' }}>
                    <View style={[styles.containerTitle, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '40%' }]} onLayout={(event) => {
                        const {x, y, width, height} = event.nativeEvent.layout;
                        this.setState({ widthTitle: width })
                    }}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 20, color: '#0070FF' }}>SALE OPERATIVE</Text>
                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 13 }}>Gestisci tutte le sale</Text>
                    </View>
                </View>
                <View style={{ paddingHorizontal: 20, alignItems: 'center' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 15 }}>
                        <View style={styles.containerIcon}>
                            {(this.state.loadingSearch)
                                ?
                                <ActivityIndicator size='small' color='#000' />
                                :
                                <AntDesign name='search1' color='#000' size={23} />
                            }
                        </View>
                        <TextInput
                            style={[styles.input, { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, paddingLeft: 0, width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 160 : (this.state.widthTitle - 120), marginRight: 10, backgroundColor: '#FFF' }]}
                            placeholderTextColor='#D3D6D7'
                            placeholder='Cerca la sala'
                            value={this.state.cercaStudio}
                            onChangeText={(cerca) => this.searchStudi(cerca)}
                        />
                        <TouchableOpacity disabled={(this.state.cercaStudio !== '') ? false : true} style={[styles.backButton, { backgroundColor: (this.state.cercaStudio !== '') ? '#FFF' : '#D3D6D7', width: 55, height: 55, paddingHorizontal: 0 }]} onPress={this.resetListPage}>
                            <AntDesign name='closecircleo' color={(this.state.cercaStudio !== '') ? '#0070FF' : 'grey'} size={23} />
                        </TouchableOpacity>
                    </View>
                </View>
                {(this.state.loading)
                    ?
                    <View style={{ paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150, paddingTop: 10, paddingBottom: Dimensions.get('screen').height / 4 }}>
                        <View style={[styles.buttonLink, { width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : Dimensions.get('screen').width - 300, paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'center' }]}>
                            <ActivityIndicator size='small' color='#0070FF' />
                        </View>
                    </View>
                    :
                    (this.state.listStudi.length > 0)
                        ?
                        <FlatList
                            data={this.state.listStudi}
                            renderItem={this.renderItem}
                            contentContainerStyle={{ 
                                paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150,
                                paddingTop: 10,
                                paddingBottom: Dimensions.get('screen').height / 4
                            }}
                            numColumns={(Dimensions.get('screen').width <= 500) ? 1 : 2}
                            keyExtractor={(item, index) => index.toString()}
                            showsVerticalScrollIndicator={false}
                            ListFooterComponent={this.renderFooter}
                            onEndReachedThreshold={0.5}
                            onEndReached={this.handleMore}
                        />
                        :
                        <View style={{ paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150, paddingTop: 10, paddingBottom: Dimensions.get('screen').height / 4 }}>
                            <View style={[styles.buttonLink, { width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : Dimensions.get('screen').width - 300, paddingHorizontal: 20, paddingVertical: 20 }]}>
                                <Feather name='alert-triangle' color='#fc3535' size={30} />
                                <View style={{ marginLeft: 15 }}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Non sono presenti sale</Text>
                                    <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Aggiungi la tua prima sala</Text>
                                </View>
                            </View>
                        </View>
                }
                <View style={styles.positionCricle}>
                    <View style={styles.circle}></View>
                </View>
                <Modal
                    visible={this.state.showModal}
                    animationType='slide'
                    presentationStyle='pageSheet'
                    transparent={false}
                    onRequestClose={() => {
                        this.setState({ showModal: false, image: '', nomeStudio: '', via: '', citta: '', cap: '', descrizione: '' })
                    }}
                >
                    <View style={[styles.container, { backgroundColor: '#FFF' }]}>
                        <View style={[styles.header, { paddingTop: 10 }]}>
                            <TouchButton style={styles.backButton} onPress={() => this.setState({ showModal: false, image: '', nomeStudio: '', via: '', citta: '', cap: '', descrizione: '' })}>
                                <Feather name='arrow-left' color='#fff' size={23} />
                            </TouchButton>
                        </View>
                        <ScrollView showsVerticalScrollIndicator={false}>
                            <View style={[styles.containerCenter, { marginBottom: Dimensions.get('screen').height / 3 }]}>
                                <View style={styles.headImage}>
                                    <TouchButton onPress={this.pickImage}>
                                        <Image source={(this.state.image != '') ? { uri: this.state.image } : require('../assets/user-default-dentop.png')} resizeMode='cover' style={{ width: 100, height: 100, borderRadius: 15 }} />
                                        <View style={styles.containerImage}>
                                            {(this.state.loading)
                                                ?
                                                <ActivityIndicator size='small' color='#000' />
                                                :
                                                <AntDesign name='edit' color='#000' size={26} />
                                            }
                                        </View>
                                    </TouchButton>
                                </View>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Nome sala*</Text>
                                <TextInput
                                    style={[styles.input, { marginBottom: 20 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Nome studio'
                                    value={this.state.nomeStudio}
                                    onChangeText={(nomeStudio) => this.setState({ nomeStudio })}
                                    maxLength={250}
                                />
                                {/*<Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Via*</Text>
                                <TextInput
                                    style={[styles.input, { marginBottom: 20 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Es. Via Roma 21'
                                    value={this.state.via}
                                    onChangeText={(via) => this.setState({ via })}
                                    maxLength={250}
                                />
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Città*</Text>
                                <TextInput
                                    style={[styles.input, { marginBottom: 20 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Nome città'
                                    value={this.state.citta}
                                    onChangeText={(citta) => this.setState({ citta })}
                                    maxLength={150}
                                />
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Cap*</Text>
                                <TextInput
                                    style={[styles.input, { marginBottom: 20 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='CAP'
                                    value={this.state.cap}
                                    onChangeText={(cap) => this.setState({ cap })}
                                    maxLength={50}
                                    keyboardType='numeric'
                                />*/}
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Descrizione</Text>
                                <TextInput
                                    style={[styles.input, { height: 150, marginBottom: 20 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Descrizione studio'
                                    value={this.state.descrizione}
                                    onChangeText={(descrizione) => this.setState({ descrizione })}
                                    multiline={true}
                                />
                                <View style={{ alignItems: 'center' }}>
                                    <TouchButton style={[styles.button, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%' }]} onPress={(this.state.isEdit) ? this.handleEditStudio : this.handleAddStudio}>
                                        {(this.state.loadingActive)
                                            ?
                                            <ActivityIndicator color='#fff' size='small' />
                                            :
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Salva</Text>
                                        }
                                    </TouchButton>
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </Modal>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#2484FF',
        overflow: 'hidden'
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : 300,
        marginTop: 45,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center',
        marginTop: 20
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    inputDate: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    buttonMore: {
        backgroundColor: '#0070FF',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        paddingHorizontal: 13,
        borderRadius: 18,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        minWidth: 100
    },
    listUser: {
        backgroundColor: '#f5f5f5',
        paddingHorizontal: 20,
        paddingVertical: 12,
        borderColor: '#ededed',
        borderTopWidth: 1,
    },
    containerIcon: {
        backgroundColor: '#fff',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        width: 55,
        height: 55,
        alignItems: 'center',
        justifyContent: 'center'
    },
    positionCricle: {
        position: 'absolute',
        bottom: - Dimensions.get('screen').width / 2,
        right: - Dimensions.get('screen').width / 2,
        zIndex: -1,
        overflow: 'hidden'
    },
    circle: {
        backgroundColor: '#8d4472',
        width: Dimensions.get('screen').width,
        height: Dimensions.get('screen').width,
        borderRadius: Dimensions.get('screen').width,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    responsiveCallButton: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '47%',
        marginTop: 0
    },
    responsiveCall: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '30%'
    },
    containerTitle: {
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: 15,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    headImage: {
        paddingBottom: 15,
        paddingHorizontal: 20,
        alignItems: 'center'
    },
    containerImage: {
        width: 100,
        height: 100,
        borderRadius: 15,
        backgroundColor: '#ffffff1c',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        zIndex: 2
    },
});