import React, { Component } from 'react';
import { TouchableOpacity, Text, View, StyleSheet } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';

class AgendaComponent extends Component {
    shouldComponentUpdate(nextProps) {
        // Condizione di aggiornamento: confronta le props correnti con quelle future
        return nextProps.item !== this.props.item;
    }

    render() {
        const { item, onPress, onDelete } = this.props;
        const country_code = JSON.parse(item.country_code);

        return (
            <TouchableOpacity
                key={item.id}
                style={styles.appointmentContainer}
                onPress={() => onPress(item, country_code)}
            >
                <Text style={styles.appointmentText}>{item.time_slot} - {item.nome} {item.cognome}</Text>
                <Text style={styles.appointmentSubText}>{item.email}</Text>
                <Text style={styles.appointmentSubText}>+{country_code.callingCode[0]}{item.telefono}</Text>
                <Text style={styles.appointmentSubText}><Text style={styles.appointmentSubTextTitle}>Studio:</Text> {item.nome_studio}</Text>
                <Text style={styles.appointmentSubText}><Text style={styles.appointmentSubTextTitle}>Operatore:</Text> {item.nome_operatore} {item.cognome_operatore}</Text>
                <View style={styles.positionDelete}>
                    <TouchableOpacity style={styles.buttonDelete} onPress={() => onDelete(item.id, item)}>
                        <Feather name='x' size={18} color={'#fff'} />
                    </TouchableOpacity>
                </View>
                <View style={styles.positionStato}>
                    <View style={[styles.containerStato, {
                        backgroundColor: (item.stato == 0) ? '#f8d54c' : (item.stato == 1) ? 'green' : '#fc3535'
                    }]}>
                        <Text style={[styles.appointmentSubText, {
                            color: (item.stato == 0) ? '#000' : (item.stato == 1) ? '#fff' : '#fff',
                            textAlign: 'center',
                            fontFamily: 'DM-Medium',
                            marginLeft: 10,
                            fontSize: 12
                        }]}>
                            {(item.stato == 0)
                                ?
                                'IN ATTESA'
                                :
                                (item.stato == 1)
                                    ?
                                    'APPROVATO'
                                    :
                                    'RIFIUTATO'
                            }
                        </Text>
                    </View>
                </View>
            </TouchableOpacity>
        );
    }
}

const styles = StyleSheet.create({
    appointmentContainer: {
        backgroundColor: '#fff',
        padding: 15,
        borderRadius: 10,
        marginRight: 10,
        marginTop: 17,
    },
    appointmentText: {
        fontFamily: 'DM-Bold',
        fontSize: 16,
    },
    appointmentSubText: {
        fontFamily: 'DM-Regular',
        fontSize: 14,
        color: '#6d6e72',
    },
    appointmentSubTextTitle: {
        fontFamily: 'DM-Medium',
    },
    positionDelete: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 2,
        paddingTop: 8,
        paddingRight: 8
    },
    buttonDelete: {
        backgroundColor: '#fc3535',
        width: 26,
        height: 26,
        borderRadius: 22.5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    positionStato: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 2
    },
    containerStato: {
        backgroundColor: '#f8d54c',
        width: 100,
        height: 50,
        borderBottomRightRadius: 10,
        borderTopLeftRadius: 50,
        alignItems: 'center',
        justifyContent: 'center'
    }
})

export default AgendaComponent;