import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Dimensions, Alert, TouchableOpacity, TextInput } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather } from '@expo/vector-icons';
import * as Crypto from 'expo-crypto';
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class ResetPasswordScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            email: ''
        }
    }

    recuperaEmail = async () => {
        this.setState({ loading: true })
        if (this.state.email != '') {
            const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('email', this.state.email);
            axios.post('https://clinicautomation.it/app/recupera_password.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    this.setState({ loading: false })
                    alert(`Successo! ${json.message}`)
                    this.handleGoBack()
                } else {
                    this.setState({ loading: false })
                    alert(`Attenzione! ${json.message}`)
                }
            }).catch((error) => {
                this.setState({ loading: false })
                console.error(error);
            })
        } else {
            this.setState({ loading: false })
            alert(`Attenzione! Compila tutti i campi obbligatori`)
        }
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('Login'); // Schermata di fallback
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='dark-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#000' size={23} />
                    </TouchableOpacity>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={styles.containerCenter}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, textAlign: 'center', marginBottom: 25, color: '#fff' }}>Recupera Password</Text>
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#D3D6D7'
                            placeholder='Indirizzo email'
                            value={this.state.email}
                            keyboardType='email-address'
                            onChangeText={(email) => this.setState({ email: email.toLowerCase().trim() })}
                        />
                        <View style={{ alignItems: 'center' }}>
                            <TouchableOpacity style={[styles.button, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '50%' }]} onPress={this.recuperaEmail}>
                                {(this.state.loading)
                                    ?
                                    <ActivityIndicator color='#000' size='small' />
                                    :
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#000' }}>Recupera</Text>
                                }
                            </TouchableOpacity>
                        </View>
                    </View>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#0070FF'
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : Dimensions.get('screen').width / 3,
        marginTop: Dimensions.get('window').height / 3.5
    },
    button: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
    input: {
        backgroundColor: '#2484FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#fff',
        fontSize: 16
    },
    header: {
        backgroundColor: '#fff',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'flex-start',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#f5f5f5',
        width: 50,
        height: 50,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    }
});