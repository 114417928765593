import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

// Componente personalizzato per gli eventi
const CustomEvent = ({ event }) => {
  return (
    <View style={[styles.event, { backgroundColor: event.color || '#3174ad' }]}>
      <Text style={styles.text}>{event.title}</Text>
      <Text style={styles.subText}>{event.description}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  event: {
    borderRadius: 10,
    padding: 5
  },
  text: {
    color: 'white',
    fontSize: 14,
    fontFamily: 'DM-Bold'
  },
  subText: {
    color: 'white',
    fontSize: 12,
    fontFamily: 'DM-Regular'
  }
});

export default CustomEvent;
