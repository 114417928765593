import React from 'react';
import { StyleSheet, Text, View, Dimensions, Image, Platform } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { AntDesign } from '@expo/vector-icons';
import ViewShot, { releaseCapture } from 'react-native-view-shot';

export default class DentaturaOld extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
            <>
            <ViewShot ref={this.props.refSuperiore} options={{ format: 'jpg', quality: 1.0 }}>
                <View style={[styles.headImage, { paddingHorizontal: 30, paddingVertical: 20, backgroundColor: '#fff' }]}>
                    {(this.props.forcazioni == false)
                        &&
                        <>
                        <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 15 : 12, left: (Platform.OS == 'android') ? 127 : 119 }]}>
                            {(this.props.stateDataSuperiore.includes(11))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(11) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>11</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(11)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>11</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 33 : 30, left: (Platform.OS == 'android') ? 83 : 79 }]}>
                            {(this.props.stateDataSuperiore.includes(12))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(12) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>12</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(12)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>12</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 61 : 60, left: (Platform.OS == 'android') ? 56 : 53 }]}>
                            {(this.props.stateDataSuperiore.includes(13))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(13) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>13</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(13)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>13</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 87 : 86, left: (Platform.OS == 'android') ? 38 : 35 }]}>
                            {(this.props.stateDataSuperiore.includes(14))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(14) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>14</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(14)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>14</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        </>
                    }
                    <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 117 : 115, left: (Platform.OS == 'android') ? 21 : 18 }]}>
                        {(this.props.stateDataSuperiore.includes(15))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(15) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>15</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(15)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>15</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 153 : 147, left: (Platform.OS == 'android') ? 8 : 7 }]}>
                        {(this.props.stateDataSuperiore.includes(16))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(16) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>16</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(16)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>16</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 190 : 182, left: 2 }]}>
                        {(this.props.stateDataSuperiore.includes(17))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(17) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>17</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(17)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>17</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 230 : 220, left: 1 }]}>
                        {(this.props.stateDataSuperiore.includes(18))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(18) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>18</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(18)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>18</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    {(this.props.forcazioni == false)
                        &&
                        <>
                        <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 15 : 12, right: (Platform.OS == 'android') ? 127 : 119 }]}>
                            {(this.props.stateDataSuperiore.includes(21))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(21) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>21</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(21)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>21</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 33 : 30, right: (Platform.OS == 'android') ? 83 : 79 }]}>
                            {(this.props.stateDataSuperiore.includes(22))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(22) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>22</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(22)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>22</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 61 : 60, right: (Platform.OS == 'android') ? 56 : 53 }]}>
                            {(this.props.stateDataSuperiore.includes(23))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(23) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>23</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(23)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>23</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 87 : 86, right: (Platform.OS == 'android') ? 38 : 35 }]}>
                            {(this.props.stateDataSuperiore.includes(24))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(24) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>24</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(24)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>24</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        </>
                    }
                    <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 117 : 115, right: (Platform.OS == 'android') ? 21 : 18 }]}>
                        {(this.props.stateDataSuperiore.includes(25))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(25) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>25</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(25)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>25</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 153 : 147, right: (Platform.OS == 'android') ? 8 : 7 }]}>
                        {(this.props.stateDataSuperiore.includes(26))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(26) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>26</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(26)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>26</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 190 : 182, right: 2 }]}>
                        {(this.props.stateDataSuperiore.includes(27))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(27) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>27</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(27)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>27</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 230 : 220, right: 1 }]}>
                        {(this.props.stateDataSuperiore.includes(28))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(28) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>28</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(28)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>28</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <Image source={(this.props.forcazioni == true) ? require('../assets/dentatura-superiore-deselezione.png') : require('../assets/dentatura-superiore.png')} resizeMode='contain' style={{ width: Dimensions.get('screen').width - 120, height: Dimensions.get('screen').width - 120 }} />
                </View>
            </ViewShot>
                {(this.props.edentulia == true)
                    &&
                    <View style={{ alignItems: 'center' }}>
                        <TouchableOpacity style={[styles.headImage, { justifyContent: 'center' }]} onPress={this.props.functionEdentuliaSuperiore}>
                            <AntDesign name={(this.props.edentuliaTotSuperore) ? 'checkcircle' : 'minuscircleo'} size={25} color={(this.props.edentuliaTotSuperore) ? '#ffc354' : '#000'} />
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginLeft: 10 }}>Edentulia totale</Text>
                        </TouchableOpacity>
                    </View>
                }
            <ViewShot ref={this.props.refInferiore} options={{ format: 'jpg', quality: 1.0 }}>
                <View style={[styles.headImage, { paddingHorizontal: 30, paddingVertical: 20, backgroundColor: '#fff' }]}>
                    <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 66 : 62, left: 1 }]}>
                        {(this.props.stateDataInferiore.includes(48))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(48) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>48</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionInferiore(48)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>48</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 106 : 97, left: 2 }]}>
                        {(this.props.stateDataInferiore.includes(47))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(47) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>47</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionInferiore(47)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>47</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 141 : 135, left: (Platform.OS == 'android') ? 9 : 9 }]}>
                        {(this.props.stateDataInferiore.includes(46))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(46) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>46</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionInferiore(46)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>46</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 180 : 175, left: (Platform.OS == 'android') ? 24 : 24 }]}>
                        {(this.props.stateDataInferiore.includes(45))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(45) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>45</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionInferiore(45)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>45</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    {(this.props.forcazioni == false)
                        &&
                        <>
                        <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 220 : 209, left: (Platform.OS == 'android') ? 46 : 46 }]}>
                            {(this.props.stateDataInferiore.includes(44))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(44) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>44</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionInferiore(44)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>44</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 245 : 230, left: (Platform.OS == 'android') ? 72 : 69 }]}>
                            {(this.props.stateDataInferiore.includes(43))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(43) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>43</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionInferiore(43)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>43</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 260 : 245, left: (Platform.OS == 'android') ? 105 : 98 }]}>
                            {(this.props.stateDataInferiore.includes(42))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(42) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>42</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionInferiore(42)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>42</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 268 : 251, left: (Platform.OS == 'android') ? 137 : 129 }]}>
                            {(this.props.stateDataInferiore.includes(41))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(41) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>41</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionInferiore(41)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>41</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        </>
                    }
                    <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 66 : 62, right: 1 }]}>
                        {(this.props.stateDataInferiore.includes(38))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(38) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>38</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionInferiore(38)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>38</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 106 : 97, right: 2 }]}>
                        {(this.props.stateDataInferiore.includes(37))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(37) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>37</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionInferiore(37)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>37</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 141 : 135, right: (Platform.OS == 'android') ? 9 : 9 }]}>
                        {(this.props.stateDataInferiore.includes(36))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(36) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>36</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionInferiore(36)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>36</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 180 : 175, right: (Platform.OS == 'android') ? 24 : 24 }]}>
                        {(this.props.stateDataInferiore.includes(35))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(35) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>35</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionInferiore(35)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>35</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    {(this.props.forcazioni == false)
                        &&
                        <>
                        <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 220 : 209, right: (Platform.OS == 'android') ? 46 : 46 }]}>
                            {(this.props.stateDataInferiore.includes(34))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(34) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>34</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionInferiore(34)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>34</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 245 : 230, right: (Platform.OS == 'android') ? 72 : 69 }]}>
                            {(this.props.stateDataInferiore.includes(33))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(33) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>33</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionInferiore(33)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>33</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 260 : 245, right: (Platform.OS == 'android') ? 105 : 98 }]}>
                            {(this.props.stateDataInferiore.includes(32))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(32) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>32</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionInferiore(32)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>32</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (Platform.OS == 'android') ? 268 : 251, right: (Platform.OS == 'android') ? 137 : 129 }]}>
                            {(this.props.stateDataInferiore.includes(31))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(31) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#ffc354' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#000' }}>31</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionInferiore(31)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0795FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>31</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        </>
                    }
                    <Image source={(this.props.forcazioni == true) ? require('../assets/dentatura-inferiore-deselezione.png') : require('../assets/dentatura-inferiore.png')} resizeMode='contain' style={{ width: Dimensions.get('screen').width - 120, height: Dimensions.get('screen').width - 120 }} />
                </View>
            </ViewShot>
                {(this.props.edentulia == true)
                    &&
                    <View style={{ alignItems: 'center', marginTop: 30 }}>
                        <TouchableOpacity style={[styles.headImage, { justifyContent: 'center' }]} onPress={this.props.functionEdentuliaInferiore}>
                            <AntDesign name={(this.props.edentuliaTotInferiore) ? 'checkcircle' : 'minuscircleo'} size={25} color={(this.props.edentuliaTotInferiore) ? '#ffc354' : '#000'} />
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginLeft: 10 }}>Edentulia totale</Text>
                        </TouchableOpacity>
                    </View>
                }
            </>
        )
    }
}

const styles = StyleSheet.create({
    headImage: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    counterTooth: {
        width: 30,
        height: 30,
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#0795FF'
    },
    counterPosition: {
        position: 'absolute',
        zIndex: 2
    }
});