import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Switch, Dimensions, Alert, TouchableOpacity, TextInput, Image } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather, AntDesign } from '@expo/vector-icons';
import RNPickerSelect from 'react-native-picker-select';
import PhoneInput from "react-native-phone-number-input";
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/it';

export default class CampagneScreen extends React.Component {
    constructor(props) {
        super(props)
        this.searchInput = React.createRef()
        this.state = {
            loading: false,
            idPaziente: '',
            cerca: '',
            searchResult: [],
            title: '',
            campagna: null,
            prezzo: '',
            note: '',
            loadingData: false,
            showDataSecond: false,
            usersSelect: [],
            pazienti: [],
            loadingFinish: false,
            isLoading: true,
            page: 1,
            automazione: {},
            maxUser: 100,
            maxSelect: 50,
            isEnabled: true
        }
    }

    componentDidMount() {
        this.checkAuth()
        this.checkCampagne()
        this.getAutomazione('67')
        this.getListofData()
    }

    checkCampagne = async () => {
        const detail = await AsyncStorage.getItem('campagne_massive')
        if (detail !== null && detail !== '' && detail !== undefined) {
            const detail_campagne = JSON.parse(detail)
            if (detail_campagne.users == 100) {
                const date_send = moment(detail_campagne.date_send).add(2, 'days').format('YYYY-MM-DD')
                const today_date = moment(new Date()).format('YYYY-MM-DD')
                if (date_send > today_date) {
                    this.setState({
                        maxUser: 0,
                        maxSelect: 0,
                        isEnabled: false
                    })
                } else {
                    await AsyncStorage.removeItem('campagne_massive')
                    this.setState({
                        maxUser: 100,
                        maxSelect: 50,
                        isEnabled: true
                    })
                }
            } else {
                this.setState({
                    maxUser: 100 - detail_campagne.users,
                    maxSelect: (detail_campagne.users > 50) ? 100 - detail_campagne.users : 50,
                    isEnabled: true
                })
            }
        } else {
            this.setState({
                maxUser: 100,
                maxSelect: 50,
                isEnabled: true
            })
        }
    }

    getAutomazione = (id_automazione) => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_automazione', id_automazione);
        axios.post('https://clinicautomation.it/app/get_automazione.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                this.setState({ automazione: {
                    descrizione: json.descrizione,
                    id: json.id,
                    nome: json.nome,
                    url: json.url
                } })
            } else {
                this.setState({ automazione: {} })
            }
        }).catch((error) => {
            this.setState({ automazione: {} })
            console.error(error);
        })
    }

    checkAuth = async () => {
        const id = await AsyncStorage.getItem('id')
        if (id != null && id != undefined && id != '') {

        } else {
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'Login' }],
            })
        }
    }

    getListofData = async () => {
        this.setState({ isLoading: true })
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', this.state.page);
        formData.append('num_per_page', 100);
        axios.post('https://clinicautomation.it/app/pazienti.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ pazienti: json, isLoading: false, loadingFinish: false })
            } else {
                this.setState({ pazienti: [], isLoading: false, loadingFinish: true })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    caricaAltro = () => {
        this.setState({ page: this.state.page + 1 })
        setTimeout(() => {
            this.getListofData()
        }, 500);
    }

    caricaMeno = () => {
        this.setState({ page: this.state.page - 1 })
        setTimeout(() => {
            this.getListofData()
        }, 500);
    }

    continuePatientsQueryLike = async (searchText) => {
        this.setState({ cerca: searchText, loadingData: true })
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', 1);
        formData.append('search_item', searchText);
        axios.post('https://clinicautomation.it/app/search_pazienti.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ searchResult: json, loadingData: false })
            } else {
                this.setState({ searchResult: [], loadingData: false })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    selectPaziente = (item) => {
        const usersSelect = this.state.usersSelect
        const findUser = usersSelect.filter(e => e.uid == item.id)
        if (findUser.length > 0) {
            const findIndex = usersSelect.findIndex(e => e.uid == item.id)
            if (findIndex !== -1) {
                usersSelect.splice(findIndex, 1)
                this.setState({ usersSelect })
            }
        } else {
            if (usersSelect.length < this.state.maxSelect) {
                const country_code = JSON.parse(item.country_code)
                usersSelect.push({
                    nome: item.nome,
                    cognome: item.cognome,
                    email: item.email,
                    telefono: `${country_code.callingCode[0]}${item.telefono}`,
                    uid: item.id
                })
                this.setState({ usersSelect })
            } else {
                alert("Attenzione! Hai raggiunto il numero massimo di utenti")
            }
        }
    }

    selectAllPazienti = () => {
        const pazienti = this.state.pazienti
        const usersSelect = this.state.usersSelect
        pazienti.forEach(element => {
            const findUser = usersSelect.filter(e => e.uid == element.id)
            if (findUser.length > 0) {

            } else {
                if (usersSelect.length < this.state.maxSelect) {
                    const country_code = JSON.parse(element.country_code)
                    usersSelect.push({
                        nome: element.nome,
                        cognome: element.cognome,
                        email: element.email,
                        telefono: `${country_code.callingCode[0]}${element.telefono}`,
                        uid: element.id
                    })
                    this.setState({ usersSelect })
                }
            }
        });
    }

    deletePazienteSelectRequest = (index) => {
        if (confirm("Attenzione! Sei sicuro di voler eliminare il paziente?") == true) {
            this.deletePazienteSelect(index)
        }
    }

    deletePazienteSelect = (index) => {
        const usersSelect = this.state.usersSelect
        usersSelect.splice(index, 1)
        this.setState({ usersSelect })
    }

    sendCampagna = async () => {
        if (this.state.usersSelect.length > 0 && this.state.note !== '') {
            this.setState({ loading: true })
            if (Object.keys(this.state.automazione).length === 0) {
                this.setState({ loading: false, page: 1, usersSelect: [], note: '' })
                setTimeout(() => {
                    this.getListofData()
                }, 500);
                alert("Campagna inviata! La campagna è stata inviata con successo")
            } else {
                const idStudio = await AsyncStorage.getItem('id')
                const telefonoStudio = await AsyncStorage.getItem('telefono')
                const country_code = JSON.parse(await AsyncStorage.getItem('country_code'))
                const via = await AsyncStorage.getItem('via')
                const citta = await AsyncStorage.getItem('citta')
                const nomeStudio = await AsyncStorage.getItem('nome_studio')
                const urlScheme = encodeURIComponent(this.state.note.replace(/(\r\n|\n|\r)/g, '\\n'))
                fetch(`${this.state.automazione.url}?arrayClienti=${JSON.stringify(this.state.usersSelect)}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefonoStudio}&id-studio=${idStudio}&status=CREATO&nomeautomazione=${this.state.automazione.nome}&messaggio=${urlScheme}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json'
                    }
                })
                .then((response) => response.text())
                .then(async (element) => {
                    let old_users = 0;
                    const detail = await AsyncStorage.getItem('campagne_massive')
                    if (detail !== null && detail !== '' && detail !== undefined) {
                        const detail_parse = JSON.parse(detail)
                        old_users = detail_parse.users
                    } else {
                        old_users = 0;
                    }
                    const campagne_massive = {
                        date_send: new Date(),
                        users: this.state.usersSelect.length + old_users
                    }
                    await AsyncStorage.setItem('campagne_massive', JSON.stringify(campagne_massive))
                    this.setState({ loading: false, page: 1, usersSelect: [], note: '' })
                    setTimeout(() => {
                        this.getListofData()
                        this.checkCampagne()
                    }, 500);
                    alert("Campagna inviata! La campagna è stata inviata con successo")
                }).catch((error) => {
                    this.setState({ loading: false, page: 1, usersSelect: [], note: '' })
                    setTimeout(() => {
                        this.getListofData()
                    }, 500);
                    alert("Campagna inviata! La campagna è stata inviata con successo")
                    console.error(error);
                })
            }
        } else {
            alert("Attenzione! Per continuare assicurati di aver selezionato degli utenti e aver inserito un messaggio.")
        }
    }

    changeCampagna = (campagna) => {
        if (campagna == '10') {
            this.setState({ campagna, showDataSecond: true })
        } else {
            this.setState({ campagna, showDataSecond: false })
        }
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('App'); // Schermata di fallback
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Campagne</Text>
                    </TouchableOpacity>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={styles.containerCenter}>
                        <View style={{ alignItems: 'center', marginBottom: 15 }}>
                            <View style={styles.containerLogo}>
                                <Image source={require('../assets/icon-campagne.png')} resizeMode='contain' style={{ width: 70, height: 70 }} />
                            </View>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 19 }}>Campagne Massive</Text>
                        </View>
                        {/*(this.state.usersSelect.length > 0)
                            &&
                            <>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10, marginTop: 10 }}>Pazienti selezionati: {this.state.usersSelect.length}/100</Text>
                            <View style={[styles.input, { height: 'auto', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }]}>
                                {this.state.usersSelect.map((item, index) => {
                                    return(
                                        <TouchableOpacity key={index} onPress={() => this.deletePazienteSelectRequest(index)} style={[styles.buttonTag, { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }]}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 13, marginRight: 5 }}>{item.name}</Text>
                                            <Feather size={18} name='x' color={'#000'} />
                                        </TouchableOpacity>
                                    )
                                })}
                            </View>
                            </>
                        */}
                        {/*<View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginTop: (this.state.usersSelect.length > 0) ? 0 : 10 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginRight: 7 }}>Seleziona il paziente*</Text>
                            {(this.state.loadingData)
                                &&
                                <ActivityIndicator size='small' color='#000' />
                            }
                        </View>
                        <TextInput
                            style={[styles.input, { borderBottomLeftRadius: (this.state.searchResult.length > 0) ? 0 : 10, borderBottomRightRadius: (this.state.searchResult.length > 0) ? 0 : 10, marginBottom: 0 }]}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Inserisci il nome del Paziente'
                            value={this.state.cerca}
                            //onChangeText={(cerca) => {this.setState({ cerca }); this.filterResult(cerca)}}
                            onChangeText={(cerca) => this.continuePatientsQueryLike(cerca)}
                            ref={this.searchInput}
                            //editable={(this.state.loadingData) ? false : true}
                        />*/}
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                            <View>
                                <TouchableOpacity style={[styles.buttonMore, { backgroundColor: '#fff' }]} onPress={this.selectAllPazienti}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#0070FF' }}>
                                        Seleziona tutto
                                    </Text>
                                </TouchableOpacity>
                                {(this.state.usersSelect.length > 0)
                                    &&
                                    <TouchableOpacity style={[styles.buttonMore, { backgroundColor: '#fff', marginTop: 15 }]} onPress={() => this.setState({ usersSelect: [] })}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#0070FF' }}>
                                            Deseleziona
                                        </Text>
                                    </TouchableOpacity>
                                }
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                {(this.state.page > 1)
                                    &&
                                    <TouchableOpacity style={[styles.buttonMore, { minWidth: 60, marginRight: 10, flexDirection: 'row' }]} onPress={this.caricaMeno}>
                                        <AntDesign name='leftcircle' color={'#fff'} size={21} />
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 13, color: '#fff', marginLeft: 10 }}>
                                            {(Dimensions.get('screen').width <= 500) ? this.state.page - 1 : `Pagina ${this.state.page - 1}`}
                                        </Text>
                                    </TouchableOpacity>
                                }
                                {(!this.state.loadingFinish)
                                    &&
                                    <TouchableOpacity style={[styles.buttonMore, { minWidth: 60, flexDirection: 'row' }]} onPress={this.caricaAltro}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 13, color: '#fff', marginRight: 10 }}>
                                            {(Dimensions.get('screen').width <= 500) ? this.state.page + 1 : `Pagina ${this.state.page + 1}`}
                                        </Text>
                                        <AntDesign name='rightcircle' color={'#fff'} size={21} />
                                    </TouchableOpacity>
                                }
                            </View>
                        </View>
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10, marginTop: 10 }}>Pazienti selezionati: {this.state.usersSelect.length}/{this.state.maxSelect}{'\n'}Invii rimanenti: {this.state.maxUser}</Text>
                        {(this.state.isLoading)
                            ?
                            <View style={{ alignItems: 'center' }}>
                                <ActivityIndicator size='small' color='#0070FF' />
                            </View>
                            :
                            (this.state.pazienti.length > 0)
                                ?
                                this.state.pazienti.map((item, index) => {
                                    let counter = index + 1;
                                    const country_code = JSON.parse(item.country_code)
                                    const resultFilter = this.state.usersSelect.filter(e => e.uid == item.id)
                                    return(
                                        <TouchableOpacity onPress={() => this.selectPaziente(item)} key={item.id} style={[styles.listUser, {
                                            borderBottomLeftRadius: (counter == this.state.pazienti.length) ? 10 : 0,
                                            borderBottomRightRadius: (counter == this.state.pazienti.length) ? 10 : 0,
                                            borderTopLeftRadius: (counter == 1) ? 10 : 0,
                                            borderTopRightRadius: (counter == 1) ? 10 : 0
                                        }]}>
                                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                {(resultFilter.length > 0)
                                                    ?
                                                    <View style={styles.checkSelected}>
                                                        <AntDesign name='check' color={'#fff'} size={20} />
                                                    </View>
                                                    :
                                                    <View style={styles.chek}></View>
                                                }
                                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginLeft: 15 }}>
                                                    {item.nome.trim()} {item.cognome.trim()}{'\n'}
                                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14 }}>+{country_code.callingCode[0]}{item.telefono}</Text>
                                                </Text>
                                            </View>
                                        </TouchableOpacity>
                                    )
                                })
                                :
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, textAlign: 'center' }}>Non ci sono pazienti da visualizzare</Text>

                        }
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10, marginTop: 20 }}>Messaggio</Text>
                        <TextInput
                            style={[styles.input, { height: 150 }]}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Messaggio campagna'
                            value={this.state.note}
                            onChangeText={(note) => this.setState({ note })}
                            multiline={true}
                        />
                        <View style={{ alignItems: 'center' }}>
                            <TouchableOpacity style={[styles.button, { backgroundColor: (this.state.isEnabled) ? '#0070FF' : '#ededed' }]} disabled={!this.state.isEnabled} onPress={this.sendCampagna}>
                                {(this.state.loading)
                                    ?
                                    <ActivityIndicator color='#fff' size='small' />
                                    :
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Salva e continua</Text>
                                }
                            </TouchableOpacity>
                            {(!this.state.isEnabled)
                                &&
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginTop: 20, textAlign: 'center' }}>Hai superato il limite di campagne massive.{'\n'}Potrai riutilizzarle entro due giorni</Text>
                            }
                        </View>
                    </View>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : 300,
        marginTop: 25,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center',
        marginTop: 20
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'flex-start',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    listUser: {
        backgroundColor: '#f5f5f5',
        paddingHorizontal: 20,
        paddingVertical: 12,
        borderColor: '#ededed',
        borderTopWidth: 1,
    },
    containerLogo: {
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems:'center',
        width: 85,
        height: 85,
        borderRadius: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 10
    },
    buttonTag: {
        backgroundColor: 'lightgrey',
        paddingVertical: 4.5,
        paddingHorizontal: 10,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 7,
        marginBottom: 10
    },
    chek: {
        backgroundColor: '#fff',
        borderColor: '#0070FF',
        borderWidth: 1.5,
        width: 25,
        height: 25,
        borderRadius: 7
    },
    checkSelected: {
        backgroundColor: '#0070FF',
        borderColor: '#0070FF',
        borderWidth: 1.5,
        width: 25,
        height: 25,
        borderRadius: 7,
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonMore: {
        backgroundColor: '#0070FF',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        paddingHorizontal: 13,
        borderRadius: 18,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        minWidth: 100
    },
});