import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Dimensions, Alert, Platform, TouchableOpacity, TextInput } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather } from '@expo/vector-icons';
import PhoneInput from "react-native-phone-number-input";
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Notifications from 'expo-notifications';
import * as WebBrowser from 'expo-web-browser';
import * as Application from 'expo-application';
import * as Device from 'expo-device';
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class RegisterScreen extends React.Component {
    constructor(props) {
        super(props)
        this.phoneRef = React.createRef()
        this.state = {
            loading: false,
            nome: '',
            cognome: '',
            email: '',
            password: '',
            telefono: '',
            codiceAgente: '',
            country: {
                "callingCode": [
                    "39",
                ],
                "cca2": "IT",
                "currency": [
                    "EUR",
                ],
                "flag": "flag-it",
                "name": "Italy",
                "region": "Europe",
                "subregion": "Southern Europe"
            }
        }
    }

    register = async () => {
        this.setState({ loading: true })
        if (this.state.email != '' && this.state.nome != '' && this.state.cognome != '' && this.state.password != '' && this.state.telefono != '') {
            const isValidaNumber = this.phoneRef.current.isValidNumber(this.state.telefono)
            if (isValidaNumber == true) {
                const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
                const passwordHash = CryptoJS.MD5(this.state.password).toString(CryptoJS.enc.Hex)
                let arrayDevices = [];
                let formData = new FormData();
                formData.append('token', token);
                formData.append('email', this.state.email);
                formData.append('password', passwordHash);
                formData.append('nome', this.state.nome);
                formData.append('cognome', this.state.cognome);
                formData.append('telefono', this.state.telefono);
                formData.append('country_code', JSON.stringify(this.state.country))
                formData.append('devices', JSON.stringify(arrayDevices));
                formData.append('codice_agente', this.state.codiceAgente);
                axios.post('https://clinicautomation.it/app/register.php', formData, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(async (response) => {
                    const json = response.data;
                    if (json.code == true) {
                        let formData2 = new FormData();
                        formData2.append('token', token);
                        formData2.append('email', this.state.email);
                        formData2.append('password', passwordHash);
                        axios.post('https://clinicautomation.it/app/login.php', formData2, {
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(async (response) => {
                            const json = response.data;
                            if (json.code == true) {
                                await AsyncStorage.setItem('id', json.id)
                                await AsyncStorage.setItem('nome', json.nome)
                                await AsyncStorage.setItem('cognome', json.cognome)
                                //await AsyncStorage.setItem('data_scadenza', json.data_scadenza)
                                await AsyncStorage.setItem('email', json.email)
                                await AsyncStorage.setItem('password', passwordHash)
                                await AsyncStorage.setItem('telefono', json.telefono)
                                await AsyncStorage.setItem('country_code', json.country_code)
                                /*await AsyncStorage.setItem('data_inizio', json.data_inizio)
                                await AsyncStorage.setItem('is_prova', json.is_prova)
                                await AsyncStorage.setItem('periodo_prova', json.periodo_prova)
                                await AsyncStorage.setItem('abbonamento_attivo', json.abbonamento_attivo)
                                const emailTest = json.email.split('@')[1]
                                if (emailTest == 'icloud.com') {
                                    this.props.navigation.replace('App')
                                } else {
                                    this.props.navigation.replace('Fatturazione')
                                }*/
                                this.setState({ loading: false })
                                this.props.navigation.replace('App')
                            } else {
                                this.setState({ loading: false })
                                alert(`Attenzione! ${json.message}`)
                            }
                        }).catch((error) => {
                            this.setState({ loading: false })
                            console.error(error);
                        })
                    } else {
                        this.setState({ loading: false })
                        alert(`Attenzione! ${json.message}`)
                    }
                }).catch((error) => {
                    this.setState({ loading: false })
                    console.error(error);
                })
            } else {
                this.setState({ loading: false })
                alert(`Attenzione! Il numero di telefono inserito non è valido`)
            }
        } else {
            this.setState({ loading: false })
            alert(`Attenzione! Compila tutti i campi`)
        }
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('Login'); // Schermata di fallback
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                    </TouchableOpacity>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={styles.containerCenter}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, textAlign: 'center', marginBottom: 25 }}>Registrati Gratuitamente</Text>
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Nome'
                            value={this.state.nome}
                            onChangeText={(nome) => this.setState({ nome })}
                        />
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Cognome'
                            value={this.state.cognome}
                            onChangeText={(cognome) => this.setState({ cognome })}
                        />
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Indirizzo email'
                            value={this.state.email}
                            onChangeText={(email) => this.setState({ email: email.toLowerCase().trim() })}
                            keyboardType='email-address'
                            autoCapitalize='none'
                        />
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Password'
                            value={this.state.password}
                            onChangeText={(password) => this.setState({ password: password.trim() })}
                            secureTextEntry={true}
                        />
                        <PhoneInput
                            ref={this.phoneRef}
                            defaultCode="IT"
                            layout="first"
                            withDarkTheme={false}
                            withShadow={false}
                            placeholder='Numero di cellulare'
                            containerStyle={{ width: '100%', borderTopRightRadius: 10, borderBottomRightRadius: 10, marginBottom: 20 }}
                            textContainerStyle={{ borderTopRightRadius: 10, borderBottomRightRadius: 10, paddingVertical: 10, height: 55, backgroundColor: '#f5f5f5' }}
                            textInputStyle={{ fontFamily: 'DM-Regular', fontSize: 16 }}
                            codeTextStyle={{ fontFamily: 'DM-Regular', fontSize: 16 }}
                            flagButtonStyle={{ backgroundColor: '#e8e8e8', borderTopLeftRadius: 10, borderBottomLeftRadius: 10, width: (Dimensions.get('screen').width <= 500) ? '26%' : '20%' }}
                            filterProps={{ placeholder: 'Cerca nazione o prefisso' }}
                            value={this.state.telefono}
                            onChangeText={(telefono) => this.setState({ telefono })}
                            onChangeCountry={(country) => this.setState({ country })}
                        />
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Codice agente'
                            value={this.state.codiceAgente}
                            onChangeText={(codiceAgente) => this.setState({ codiceAgente })}
                        />
                        <View style={{ alignItems: 'center' }}>
                            <TouchableOpacity style={[styles.button, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '50%' }]} onPress={this.register}>
                                {(this.state.loading)
                                    ?
                                    <ActivityIndicator color='#fff' size='small' />
                                    :
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Registrati</Text>
                                }
                            </TouchableOpacity>
                        </View>
                        <Text style={{ fontSize: 14, fontFamily: 'DM-Regular', marginTop: 20, textAlign: 'center' }}>
                            Continuando accetti i <Text style={{ textDecorationLine: 'underline' }} onPress={async () => await WebBrowser.openBrowserAsync('https://www.iubenda.com/termini-e-condizioni/84618713')}>Termini e Condizioni</Text> e confermi di aver letto l'informativa sulla <Text style={{ textDecorationLine: 'underline' }} onPress={async () => await WebBrowser.openBrowserAsync('https://www.iubenda.com/privacy-policy/84618713')}>Privacy Policy</Text>.
                        </Text>
                    </View>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : Dimensions.get('screen').width / 3,
        marginTop: 45,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'flex-start',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 50,
        height: 50,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    }
});