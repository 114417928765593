import React from 'react';
import { StyleSheet, Text, View, StatusBar, Image, Dimensions, Alert, Platform, TouchableOpacity, TextInput, Pressable } from 'react-native';
import { ActivityIndicator, Dialog, Portal, Button, Paragraph, Provider } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import CryptoJS from 'crypto-js';
import Moment from 'moment';
import 'moment/locale/it';
import * as Notifications from 'expo-notifications';
import * as Application from 'expo-application';
import * as Device from 'expo-device';
import Constants from 'expo-constants';
import axios from 'axios';

export default class LoginScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            email: '',
            password: '',
            showDialog: false,
            titleDialog: '',
            messageDialog: ''
        }
    }

    login = async () => {
        this.setState({ loading: true })
        if (this.state.email != '' && this.state.password != '') {
            const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            const passwordHash = CryptoJS.MD5(this.state.password).toString(CryptoJS.enc.Hex)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('email', this.state.email);
            formData.append('password', passwordHash);
            axios.post('https://clinicautomation.it/app/login.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    await AsyncStorage.setItem('id', json.id)
                    await AsyncStorage.setItem('nome', json.nome)
                    await AsyncStorage.setItem('cognome', json.cognome)
                    //await AsyncStorage.setItem('data_scadenza', json.data_scadenza)
                    await AsyncStorage.setItem('email', json.email)
                    await AsyncStorage.setItem('password', passwordHash)
                    await AsyncStorage.setItem('telefono', json.telefono)
                    await AsyncStorage.setItem('country_code', json.country_code)
                    /*await AsyncStorage.setItem('data_inizio', json.data_inizio)
                    await AsyncStorage.setItem('is_prova', json.is_prova)
                    await AsyncStorage.setItem('periodo_prova', json.periodo_prova)
                    await AsyncStorage.setItem('abbonamento_attivo', json.abbonamento_attivo)*/
                    if (json.image != null && json.image != '') {
                        await AsyncStorage.setItem('image', json.image)
                    }
                    const emailTest = json.email.split('@')[1]
                    if (emailTest == 'icloud.com' || json.id == 16 || json.id == '16') {
                        this.props.navigation.replace('App')
                    } else {
                        if (json.via != null && json.via != '') {
                            await AsyncStorage.setItem('via', json.via)
                        }
                        if (json.citta != null && json.citta != '') {
                            await AsyncStorage.setItem('citta', json.citta)
                        }
                        if (json.piva != null && json.piva != '') {
                            await AsyncStorage.setItem('piva', json.piva)
                        }
                        if (json.ragione_sociale != null && json.ragione_sociale != '') {
                            await AsyncStorage.setItem('ragione_sociale', json.ragione_sociale)
                        }
                        if (json.codice_univoco != null && json.codice_univoco != '' && json.nome_studio != null && json.nome_studio != '') {
                            await AsyncStorage.setItem('codice_univoco', json.codice_univoco)
                            await AsyncStorage.setItem('nome_studio', json.nome_studio)
                            
                            this.props.navigation.replace('App')
                        } else {
                            this.props.navigation.replace('App')
                        }
                    }
                    this.setState({ loading: false })
                } else {
                    this.setState({ loading: false, titleDialog: 'Attenzione!', messageDialog: json.message, showDialog: true })
                }
            }).catch((error) => {
                this.setState({ loading: false })
                console.error(error);
            })
        } else {
            this.setState({ loading: false, titleDialog: 'Attenzione!', messageDialog: 'Compila tutti i campi obbligatori', showDialog: true })
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={{ alignItems: 'center', marginBottom: 20 }}>
                    <Image source={require('../assets/logo-clinic.png')} resizeMode='contain' style={{ width: Dimensions.get('screen').width - 80, height: 80 }} />
                </View>
                <TextInput
                    style={styles.input}
                    placeholderTextColor='#D3D6D7'
                    placeholder='Indirizzo email'
                    value={this.state.email}
                    onChangeText={(email) => this.setState({ email: email.toLowerCase().trim() })}
                    keyboardType='email-address'
                    autoCapitalize='none'
                />
                <TextInput
                    style={styles.input}
                    placeholderTextColor='#D3D6D7'
                    placeholder='Password'
                    value={this.state.password}
                    onChangeText={(password) => this.setState({ password: password.trim() })}
                    secureTextEntry={true}
                />
                <View style={{ alignItems: 'center' }}>
                    <TouchableOpacity style={[styles.button, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '50%' }]} onPress={this.login}>
                        {(this.state.loading)
                            ?
                            <ActivityIndicator color='#000' size='small' />
                            :
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18 }}>Accedi</Text>
                        }
                    </TouchableOpacity>
                </View>
                <TouchableOpacity onPress={() => this.props.navigation.navigate('Register')}>
                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 14, marginTop: 20, color: '#fff', textAlign: 'center' }}>Non hai un account? <Text style={{ color: '#f7f1e6' }}>Registrati</Text></Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => this.props.navigation.navigate('ResetPassword')}>
                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 12, marginTop: 20, color: '#fff', textAlign: 'center' }}>Hai dimenticato la password? <Text style={{ color: '#f7f1e6' }}>Recupera</Text></Text>
                </TouchableOpacity>
                <Dialog style={{
                    width: (Dimensions.get('screen').width <= 500) ? '90%' : Dimensions.get('screen').width / 3,
                    marginLeft: (Dimensions.get('screen').width <= 500) ? 20 : (Dimensions.get('screen').width / 2) - ((Dimensions.get('screen').width / 3) / 2)
                }} visible={this.state.showDialog} onDismiss={() => this.setState({ showDialog: false })}>
                    {(this.state.titleDialog.startsWith('Attenzione'))
                        ?
                        <Dialog.Icon icon="alert-circle" size={35} color='red' />
                        :
                        <Dialog.Icon icon="check-circle" size={35} color='green' />
                    }
                    <Dialog.Title style={{ fontFamily: 'DM-Medium' }}>{this.state.titleDialog}</Dialog.Title>
                    <Dialog.Content>
                        <Paragraph style={{ fontFamily: 'DM-Regular' }}>{this.state.messageDialog}</Paragraph>
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button mode="contained" textColor='#fff' labelStyle={{ fontFamily: 'DM-Medium' }} contentStyle={{ paddingHorizontal: 25, backgroundColor: '#0070FF' }} onPress={() => this.setState({ showDialog: false })}>Ok</Button>
                    </Dialog.Actions>
                </Dialog>
                {/*<View style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    zIndex: 2
                }}>
                    <View style={[styles.buttonLink, {
                        borderRadius: 0,
                        borderTopLeftRadius: 20,
                        justifyContent: 'center',
                        width: 'auto',
                        paddingHorizontal: 15,
                        backgroundColor: '#fae2cf'
                    }]}>
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13 }}>Vers 2.0 BETA</Text>
                    </View>
                </View>*/}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#0070FF',
        justifyContent: 'center',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : Dimensions.get('screen').width / 3
    },
    button: {
        backgroundColor: '#f1edff',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
    input: {
        backgroundColor: '#2484FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#fff',
        fontSize: 16,
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
});