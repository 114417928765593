import React from 'react';
import { Dimensions, Platform, StyleSheet, Text, View } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Constants from 'expo-constants';
import HomeScreen from '../screens/HomeScreen';
import PazientiScreen from '../screens/PazientiScreen';
import VisiteScreen from '../screens/VisiteScreen';
import LeadScreen from '../screens/LeadScreen';
import ProposteScreen from '../screens/ProposteScreen';
import DashboardScreen from '../screens/DashboardScreen';
import { enableScreens, enableFreeze } from 'react-native-screens';
import { AntDesign } from '@expo/vector-icons';
enableScreens(true);
enableFreeze(true);

export default class Tabs extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const Tab = createBottomTabNavigator();

        return(
            <>
            <Tab.Navigator
                screenOptions={{
                    headerShown: false,
                    lazy: true,
                    tabBarShowLabel: true,
                    tabBarStyle: styles.tabBarConfig,
                    tabBarItemStyle: { height: (Platform.OS == 'android') ? 60 : 60, paddingVertical: 6 },
                    tabBarActiveTintColor: '#fff',
                    tabBarInactiveTintColor: '#D3D6D7',
                    tabBarLabelPosition: 'below-icon',
                    tabBarHideOnKeyboard: true
                }}
                detachInactiveScreens={true}
                initialRouteName='Dashboard'
            >
                <Tab.Screen name="Dashboard" component={HomeScreen} options={{
                    tabBarIcon: ({focused, color}) => <AntDesign name="home" size={25} color={color} />,
                    tabBarLabel: ({focused, color}) => <Text style={{ fontSize: 11, paddingBottom: 5, fontFamily: 'DM-Medium', color: color }}>Home</Text>
                }} />
                <Tab.Screen name="Statistic" component={DashboardScreen} options={{
                    tabBarIcon: ({focused, color}) => <AntDesign name="dashboard" size={23} color={color} />,
                    tabBarLabel: ({focused, color}) => <Text style={{ fontSize: 11, paddingBottom: 5, fontFamily: 'DM-Medium', color: color }}>Dashboard</Text>
                }} />
                <Tab.Screen name="Paziente" component={PazientiScreen} options={{
                    tabBarIcon: ({focused, color}) => <AntDesign name="contacts" size={22} color={color} />,
                    tabBarLabel: ({focused, color}) => <Text style={{ fontSize: 11, paddingBottom: 5, fontFamily: 'DM-Medium', color: color }}>Anagrafica</Text>
                }} />
                <Tab.Screen name="Lead" component={LeadScreen} options={{
                    tabBarIcon: ({focused, color}) => <AntDesign name="team" size={22} color={color} />,
                    tabBarLabel: ({focused, color}) => <Text style={{ fontSize: 11, paddingBottom: 5, fontFamily: 'DM-Medium', color: color }}>Lead</Text>
                }} />
                <Tab.Screen name="Proposte" component={ProposteScreen} options={{
                    tabBarIcon: ({focused, color}) => <AntDesign name="tago" size={22} color={color} />,
                    tabBarLabel: ({focused, color}) => <Text style={{ fontSize: 11, paddingBottom: 5, fontFamily: 'DM-Medium', color: color }}>Proposte</Text>
                }} />
            </Tab.Navigator>
            </>
        );
    }
}

const styles = StyleSheet.create({
    tabBarConfig: {
        paddingVertical: 3,
        width: (Dimensions.get('screen').width <= 500) ? '100%' : '40%',
        height: (Platform.OS == 'android') ? 40 + Constants.statusBarHeight : (Constants.statusBarHeight <= 20) ? 60 : 40 + Constants.statusBarHeight,
        backgroundColor: '#0f69db',
        borderTopWidth: (Dimensions.get('screen').width <= 500) ? 1 : 0,
        borderTopColor: '#2484FF',
        position: (Dimensions.get('screen').width <= 500) ? 'relative' : 'absolute',
        bottom: (Dimensions.get('screen').width <= 500) ? 0 : 15,
        left: 0,
        right: 0,
        margin: 'auto',
        borderRadius: (Dimensions.get('screen').width <= 500) ? 0 : 20,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    }
})