import React from 'react';
import { StyleSheet, Text, View, StatusBar, Dimensions, Image, ScrollView, Modal, Alert, TouchableOpacity as TouchButton , Platform, Switch, FlatList, TouchableOpacity, TextInput } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { AntDesign, FontAwesome, Feather } from '@expo/vector-icons';
import { Table, Row, Rows, Cell, TableWrapper, Col } from 'react-native-table-component-2';
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RNPickerSelect from 'react-native-picker-select';
import DateTimePickerModal from "react-native-modal-datetime-picker";
import DateTimePicker from '@react-native-community/datetimepicker';
import Moment from 'moment';
import 'moment/locale/it';
import * as DocumentPicker from 'expo-document-picker';
import * as Linking from 'expo-linking';
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class LeadScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headerHeight: 0,
            listLead: []
        }
    }

    componentDidMount() {
        this._unsubscribe = this.props.navigation.addListener('focus', () => {
            this.getLead()
        });
        this.getLead()
    }

    componentWillUnmount() {
        this._unsubscribe
    }

    getLead = async () => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        axios.post('https://clinicautomation.it/app/lead_list.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ listLead: json })
            } else {
                this.setState({ listLead: [] })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    onLayout = (event) => {
        const {x, y, height, width} = event.nativeEvent.layout;
        this.setState({ headerHeight: height })
    }

    openUser = (uid) => {
        const userIndex = this.state.listLead.findIndex(e => e.id == uid)
        if (userIndex != -1) {
            this.props.navigation.navigate('LeadModifica', { paziente: JSON.stringify(this.state.listLead[userIndex]) })
        }
    }
 
    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={{ backgroundColor: '#0070FF' }} onLayout={this.onLayout}>
                    <View style={styles.header}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 20, color: '#fff' }}>Lead</Text>
                        <View style={[styles.backButton, { backgroundColor: '#0070FF' }]}>
                            <AntDesign name='pluscircleo' color='#0070FF' size={23} />
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#0070FF', marginLeft: 7 }}>Lead</Text>
                        </View>
                    </View>
                </View>
                <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={(Dimensions.get('screen').width <= 500) ? false : true}
                >
                    <View style={{
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 100
                    }}>
                        <View style={[styles.containerCard, { height: Dimensions.get('screen').height - this.state.headerHeight, }]}>
                            <View style={styles.headerCard}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, color: '#fff' }}>DA CONTATTARE</Text>
                            </View>
                            <ScrollView showsVerticalScrollIndicator={false}>
                                <View style={{ paddingHorizontal: 20, paddingBottom: Dimensions.get('screen').width / 4.5 }}>
                                    {this.state.listLead.map((item, index) => {
                                        if (item.fase_lead == 0 || item.fase_lead == "0") {
                                            const country_code = JSON.parse(item.country_code)
                                            return(
                                                <TouchableOpacity key={index} style={styles.lineItem} onPress={() => this.openUser(item.id)}>
                                                    <View style={{ width: '85%' }}>
                                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, color: '#0070FF' }}>{item.nome} {item.cognome}</Text>
                                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 14 }}>{item.email}{'\n'}+{country_code.callingCode[0]}{item.telefono}</Text>
                                                    </View>
                                                    <Feather name='arrow-right-circle' color='#0070FF' size={22} />
                                                </TouchableOpacity>
                                            )
                                        }
                                    })}
                                </View>
                            </ScrollView>
                        </View>
                        <View style={[styles.containerCard, { height: Dimensions.get('screen').height - this.state.headerHeight, }]}>
                            <View style={styles.headerCard}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, color: '#fff' }}>IRREPERIBILE</Text>
                            </View>
                            <ScrollView showsVerticalScrollIndicator={false}>
                                <View style={{ paddingHorizontal: 20, paddingBottom: Dimensions.get('screen').width / 4.5 }}>
                                    {this.state.listLead.map((item, index) => {
                                        if (item.fase_lead == 1 || item.fase_lead == "1") {
                                            const country_code = JSON.parse(item.country_code)
                                            return(
                                                <TouchableOpacity key={index} style={styles.lineItem} onPress={() => this.openUser(item.id)}>
                                                    <View style={{ width: '85%' }}>
                                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, color: '#0070FF' }}>{item.nome} {item.cognome}</Text>
                                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 14 }}>{item.email}{'\n'}+{country_code.callingCode[0]}{item.telefono}</Text>
                                                    </View>
                                                    <Feather name='arrow-right-circle' color='#0070FF' size={22} />
                                                </TouchableOpacity>
                                            )
                                        }
                                    })}
                                </View>
                            </ScrollView>
                        </View>
                        <View style={[styles.containerCard, { height: Dimensions.get('screen').height - this.state.headerHeight, }]}>
                            <View style={styles.headerCard}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, color: '#fff' }}>DA RICHIAMARE</Text>
                            </View>
                            <ScrollView showsVerticalScrollIndicator={false}>
                                <View style={{ paddingHorizontal: 20, paddingBottom: Dimensions.get('screen').width / 4.5 }}>
                                    {this.state.listLead.map((item, index) => {
                                        if (item.fase_lead == 2 || item.fase_lead == "2") {
                                            const country_code = JSON.parse(item.country_code)
                                            return(
                                                <TouchableOpacity key={index} style={styles.lineItem} onPress={() => this.openUser(item.id)}>
                                                    <View style={{ width: '85%' }}>
                                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, color: '#0070FF' }}>{item.nome} {item.cognome}</Text>
                                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 14 }}>{item.email}{'\n'}+{country_code.callingCode[0]}{item.telefono}</Text>
                                                    </View>
                                                    <Feather name='arrow-right-circle' color='#0070FF' size={22} />
                                                </TouchableOpacity>
                                            )
                                        }
                                    })}
                                </View>
                            </ScrollView>
                        </View>
                        <View style={[styles.containerCard, { height: Dimensions.get('screen').height - this.state.headerHeight, }]}>
                            <View style={styles.headerCard}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, color: '#fff' }}>APP. RIFIUTATO</Text>
                            </View>
                            <ScrollView showsVerticalScrollIndicator={false}>
                                <View style={{ paddingHorizontal: 20, paddingBottom: Dimensions.get('screen').width / 4.5 }}>
                                    {this.state.listLead.map((item, index) => {
                                        if (item.fase_lead == 3 || item.fase_lead == "3") {
                                            const country_code = JSON.parse(item.country_code)
                                            return(
                                                <TouchableOpacity key={index} style={styles.lineItem} onPress={() => this.openUser(item.id)}>
                                                    <View style={{ width: '85%' }}>
                                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, color: '#0070FF' }}>{item.nome} {item.cognome}</Text>
                                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 14 }}>{item.email}{'\n'}+{country_code.callingCode[0]}{item.telefono}</Text>
                                                    </View>
                                                    <Feather name='arrow-right-circle' color='#0070FF' size={22} />
                                                </TouchableOpacity>
                                            )
                                        }
                                    })}
                                </View>
                            </ScrollView>
                        </View>
                        <View style={[styles.containerCard, { height: Dimensions.get('screen').height - this.state.headerHeight, marginRight: 0 }]}>
                            <View style={styles.headerCard}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, color: '#fff' }}>APP. ACCETTATO</Text>
                            </View>
                            <ScrollView showsVerticalScrollIndicator={false}>
                                <View style={{ paddingHorizontal: 20, paddingBottom: Dimensions.get('screen').width / 4.5 }}>
                                    {this.state.listLead.map((item, index) => {
                                        if (item.fase_lead == 4 || item.fase_lead == "4") {
                                            const country_code = JSON.parse(item.country_code)
                                            return(
                                                <TouchableOpacity key={index} style={styles.lineItem} onPress={() => this.openUser(item.id)}>
                                                    <View style={{ width: '85%' }}>
                                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, color: '#0070FF' }}>{item.nome} {item.cognome}</Text>
                                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 14 }}>{item.email}{'\n'}+{country_code.callingCode[0]}{item.telefono}</Text>
                                                    </View>
                                                    <Feather name='arrow-right-circle' color='#0070FF' size={22} />
                                                </TouchableOpacity>
                                            )
                                        }
                                    })}
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#0070FF'
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: (Dimensions.get('screen').width - 50) - ((Dimensions.get('screen').width / 1.3) - 50),
        height: 45,
        paddingHorizontal: 10,
        paddingVertical: 6,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    header: {
        paddingHorizontal: 20,
        paddingTop: 10 + Constants.statusBarHeight,
        paddingBottom: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    containerLink: {
        paddingBottom: 30
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    head: {
        height: 50,
        backgroundColor: '#0070FF',
        paddingHorizontal: 5
    },
    text: { 
        marginVertical: 6 
    },
    input: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: (Dimensions.get('screen').width / 1.3) - 50,
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 45,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 15,
        marginRight: 10
    },
    inputSelect: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    buttonDate: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center'
    },
    widthTable: {
        width: Dimensions.get('screen').width / 3
    },
    widthRow: {
        height: 40,
        width: Dimensions.get('screen').width / 3,
        justifyContent: 'center'
    },
    backButtonModal: {
        backgroundColor: '#f5f5f5',
        width: 50,
        height: 50,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonModal: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
    uploadContainer: {
        backgroundColor: '#2484FF',
        paddingHorizontal: 20,
        paddingVertical: 15,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    uploadButton: {
        backgroundColor: '#f5f5f5',
        width: '100%',
        height: 85,
        borderRadius: 10,
        borderColor: 'grey',
        borderWidth: 3,
        borderStyle: 'dashed',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        marginBottom: 15
    },
    buttonRemoveFile: {
        backgroundColor: 'lightgrey',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 4,
        paddingHorizontal: 10,
        borderRadius: 18,
        position: 'absolute',
        top: 18,
        right: 8,
        zIndex: 3
    },
    containerTime: {
        width: Dimensions.get('screen').width,
        paddingHorizontal: 30,
        paddingVertical: 20
    },
    headerLine: {
        backgroundColor: '#0070FF',
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        paddingHorizontal: 20,
        paddingVertical: 11.5
    },
    containerLine: {
        backgroundColor: '#2484FF',
        padding: 20,
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15
    },
    lineItem: {
        backgroundColor: '#FFF',
        borderRadius: 10,
        paddingHorizontal: 12.5,
        paddingVertical: 16,
        shadowColor: "#00000085",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        marginTop: 12,
        marginHorizontal: 5,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    containerCard: {
        backgroundColor: '#ededed',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 80 : Dimensions.get('screen').width / 3.7,
        paddingBottom: 20,
        marginRight: (Dimensions.get('screen').width <= 500) ? 20 : 40
    },
    headerCard: {
        paddingHorizontal: 20,
        paddingVertical: 20,
        backgroundColor: '#2484FF',
        borderTopLeftRadius: 17,
        borderTopRightRadius: 17
    }
});