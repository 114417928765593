import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Dimensions, Alert, TouchableOpacity, TextInput } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather } from '@expo/vector-icons';
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class EditInformazioniScreen extends React.Component {
    constructor(props) {
        super(props)
        this.phoneRef = React.createRef()
        this.state = {
            loading: false,
            ragioneSociale: '',
            via: '',
            citta: '',
            piva: '',
            codiceUnivoco: '',
            nomeStudio: ''
        }
    }

    async componentDidMount() {
        this.checkAuth()
        let ragione_sociale = await AsyncStorage.getItem('ragione_sociale')
        let via = await AsyncStorage.getItem('via')
        let citta = await AsyncStorage.getItem('citta')
        let piva = await AsyncStorage.getItem('piva')
        let codice_univoco = await AsyncStorage.getItem('codice_univoco')
        let nome_studio = await AsyncStorage.getItem('nome_studio')
        this.setState({ ragioneSociale: ragione_sociale, via, citta, piva, codiceUnivoco: codice_univoco, nomeStudio: nome_studio })
    }

    checkAuth = async () => {
        const id = await AsyncStorage.getItem('id')
        if (id != null && id != undefined && id != '') {

        } else {
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'Login' }],
            })
        }
    }

    editProfilo = async () => {
        this.setState({ loading: true })
        if (this.state.ragioneSociale != '' && this.state.ragioneSociale != null && this.state.via != '' && this.state.via != null && this.state.citta != '' && this.state.citta != null && this.state.piva != '' && this.state.piva != null && this.state.codiceUnivoco != '' && this.state.codiceUnivoco != null && this.state.nomeStudio != '' && this.state.nomeStudio != null) {
            let id = await AsyncStorage.getItem('id')
            const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('ragione_sociale', this.state.ragioneSociale);
            formData.append('nome_studio', this.state.nomeStudio);
            formData.append('via', this.state.via);
            formData.append('citta', this.state.citta);
            formData.append('piva', this.state.piva);
            formData.append('codice_univoco', this.state.codiceUnivoco);
            formData.append('id_user', id);
            axios.post('https://clinicautomation.it/app/update_fatturazione.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    await AsyncStorage.setItem('ragione_sociale', this.state.ragioneSociale)
                    await AsyncStorage.setItem('via', this.state.via)
                    await AsyncStorage.setItem('citta', this.state.citta)
                    await AsyncStorage.setItem('piva', this.state.piva)
                    await AsyncStorage.setItem('codice_univoco', this.state.codiceUnivoco)
                    await AsyncStorage.setItem('nome_studio', this.state.nomeStudio)
                    this.setState({ loading: false })
                    alert(`Successo! ${json.message}`)
                } else {
                    this.setState({ loading: false })
                    alert(`Attenzione! ${json.message}`)
                }
            }).catch((error) => {
                this.setState({ loading: false })
                console.error(error);
            })
        } else {
            this.setState({ loading: false })
            alert(`Attenzione! Compila tutti i campi`)
        }
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('App'); // Schermata di fallback
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                    </TouchableOpacity>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={styles.containerCenter}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, textAlign: 'center', marginBottom: 25 }}>Informazioni aziendali</Text>
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Ragione sociale'
                            value={this.state.ragioneSociale}
                            onChangeText={(ragioneSociale) => this.setState({ ragioneSociale })}
                        />
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Nome studio'
                            value={this.state.nomeStudio}
                            onChangeText={(nomeStudio) => this.setState({ nomeStudio })}
                        />
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Indirizzo attività (Es. Via Roma, 23)'
                            value={this.state.via}
                            onChangeText={(via) => this.setState({ via })}
                        />
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Città'
                            value={this.state.citta}
                            onChangeText={(citta) => this.setState({ citta })}
                        />
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Partita Iva'
                            value={this.state.piva}
                            onChangeText={(piva) => this.setState({ piva })}
                        />
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Codice univoco o PEC'
                            value={this.state.codiceUnivoco}
                            onChangeText={(codiceUnivoco) => this.setState({ codiceUnivoco })}
                            keyboardType='email-address'
                        />
                        <View style={{ alignItems: 'center' }}>
                            <TouchableOpacity style={[styles.button, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%' }]} onPress={this.editProfilo}>
                                {(this.state.loading)
                                    ?
                                    <ActivityIndicator color='#fff' size='small' />
                                    :
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Salva</Text>
                                }
                            </TouchableOpacity>
                        </View>
                    </View>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : 300,
        marginTop: 45,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'flex-start',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 50,
        height: 50,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    }
});