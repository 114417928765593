import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Dimensions, Alert, TouchableOpacity, TextInput } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather } from '@expo/vector-icons';
import RNPickerSelect from 'react-native-picker-select';
import PhoneInput from "react-native-phone-number-input";
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class PropostaNuovoScreen extends React.Component {
    constructor(props) {
        super(props)
        this.searchInput = React.createRef()
        this.state = {
            loading: false,
            idPaziente: '',
            cerca: '',
            searchResult: [],
            title: '',
            fase: null,
            prezzo: '',
            note: '',
            venditore: '',
            loadingData: false
        }
    }

    componentDidMount() {
        this.checkAuth()
    }

    checkAuth = async () => {
        const id = await AsyncStorage.getItem('id')
        if (id != null && id != undefined && id != '') {

        } else {
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'Login' }],
            })
        }
    }

    continuePatientsQueryLike = async (searchText) => {
        this.setState({ cerca: searchText, loadingData: true })
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', 1);
        formData.append('search_item', searchText);
        axios.post('https://clinicautomation.it/app/search_pazienti.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ searchResult: json, loadingData: false })
            } else {
                this.setState({ searchResult: [], loadingData: false })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    selectPaziente = (uid, nome, cognome, item) => {
        this.setState({ idPaziente: uid, searchResult: [], cerca: `${nome} ${cognome}` })
        this.searchInput.current.blur()
    }

    salvaProposta = async () => {
        this.setState({ loading: true })
        if (this.state.idPaziente != '' && this.state.title != '' && this.state.fase != null && this.state.prezzo != '') {
            const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let id = await AsyncStorage.getItem('id')
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_user', id);
            formData.append('id_paziente', this.state.idPaziente);
            formData.append('title', this.state.title);
            formData.append('fase', this.state.fase);
            formData.append('prezzo', this.state.prezzo);
            formData.append('note', this.state.note);
            formData.append('venditore', this.state.venditore);
            axios.post('https://clinicautomation.it/app/nuova_proposta.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    this.setState({ loading: false })
                    this.handleGoBack()
                } else {
                    this.setState({ loading: false })
                    alert(`Attenzione! ${json.message}`)
                }
            }).catch((error) => {
                this.setState({ loading: false })
                console.error(error);
            })
        } else {
            this.setState({ loading: false })
            alert(`Attenzione! Compila tutti i campi obbligatori`)
        }
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('App', { screen: 'Proposte' }); // Schermata di fallback
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Aggiungi Proposta</Text>
                    </TouchableOpacity>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={styles.containerCenter}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginRight: 7 }}>Seleziona il paziente*</Text>
                            {(this.state.loadingData)
                                &&
                                <ActivityIndicator size='small' color='#000' />
                            }
                        </View>
                        <TextInput
                            style={[styles.input, { borderBottomLeftRadius: (this.state.searchResult.length > 0) ? 0 : 10, borderBottomRightRadius: (this.state.searchResult.length > 0) ? 0 : 10, marginBottom: 0 }]}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Inserisci il nome del Paziente'
                            value={this.state.cerca}
                            //onChangeText={(cerca) => {this.setState({ cerca }); this.filterResult(cerca)}}
                            onChangeText={(cerca) => this.continuePatientsQueryLike(cerca)}
                            ref={this.searchInput}
                            //editable={(this.state.loadingData) ? false : true}
                        />
                        {(this.state.searchResult.length > 0)
                            &&
                            this.state.searchResult.map((item, index) => {
                                let counter = index + 1;
                                const country_code = JSON.parse(item.country_code)
                                return(
                                    <TouchableOpacity onPress={() => this.selectPaziente(item.id, item.nome.trim(), item.cognome.trim(), item)} key={item.id} style={[styles.listUser, {
                                        borderBottomLeftRadius: (counter == this.state.searchResult.length) ? 10 : 0,
                                        borderBottomRightRadius: (counter == this.state.searchResult.length) ? 10 : 0
                                    }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>
                                            {item.nome.trim()} {item.cognome.trim()}{'\n'}
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14 }}>+{country_code.callingCode[0]}{item.telefono}</Text>
                                        </Text>
                                    </TouchableOpacity>
                                )
                            })
                        }
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10, marginTop: 20 }}>Titolo*</Text>
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Titolo'
                            value={this.state.title}
                            onChangeText={(title) => this.setState({ title })}
                            maxLength={200}
                        />
                        <View style={{ marginBottom: 20 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Fase*</Text>
                            <RNPickerSelect
                                placeholder={{ label: 'Seleziona il tipo di Fase', value: null }}
                                doneText='Fine'
                                style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                onValueChange={(fase) => this.setState({ fase })}
                                items={[
                                    { label: 'No Show', value: '0' },
                                    { label: 'Prev. Rifiutato', value: '1' },
                                    { label: 'Da presentare', value: '2' },
                                    { label: 'Prev. In Attesa', value: '3' },
                                    { label: 'Prev. Accettato', value: '4' },
                                ]}
                            />
                        </View>
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Prezzo*</Text>
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Prezzo'
                            value={this.state.prezzo}
                            onChangeText={(prezzo) => this.setState({ prezzo: prezzo.replace(/[^0-9.,]/g, '') })}
                            keyboardType='numeric'
                        />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Venditore</Text>
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Venditore'
                            value={this.state.venditore}
                            onChangeText={(venditore) => this.setState({ venditore })}
                            maxLength={100}
                        />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Note</Text>
                        <TextInput
                            style={[styles.input, { height: 85 }]}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Note'
                            value={this.state.note}
                            onChangeText={(note) => this.setState({ note })}
                            multiline={true}
                            numberOfLines={3}
                        />
                        <View style={{ alignItems: 'center' }}>
                            <TouchableOpacity style={[styles.button, {
                                width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%'
                            }]} onPress={this.salvaProposta}>
                                {(this.state.loading)
                                    ?
                                    <ActivityIndicator color='#fff' size='small' />
                                    :
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Salva e continua</Text>
                                }
                            </TouchableOpacity>
                        </View>
                    </View>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : 300,
        marginTop: 45,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center',
        marginTop: 20
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    listUser: {
        backgroundColor: '#f5f5f5',
        paddingHorizontal: 20,
        paddingVertical: 12,
        borderColor: '#ededed',
        borderTopWidth: 1,
    },
});