import React from 'react';
import { StyleSheet, Text, View, Dimensions, Image, Platform, TouchableOpacity } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import ViewShot, { releaseCapture } from 'react-native-view-shot';
import * as Device from 'expo-device';

const deviceTypeMap = {
    [Device.DeviceType.UNKNOWN]: "UNKNOWN",
    [Device.DeviceType.PHONE]: "PHONE",
    [Device.DeviceType.TABLET]: "TABLET",
    [Device.DeviceType.DESKTOP]: "DESKTOP",
    [Device.DeviceType.TV]: "TV"
};

export default class Dentatura extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            deviceType: null
        }
    }

    async componentDidMount() {
        await Device.getDeviceTypeAsync().then((deviceType) => {
            this.setState({ deviceType: deviceTypeMap[deviceType] })
        })
    }

    render() {
        return(
            <>
            <ViewShot ref={this.props.refSuperiore} style={{ alignItems: 'center', backgroundColor: '#fff' }} options={{ format: 'jpg', quality: 1.0 }}>
                <View style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff', width: Dimensions.get('screen').width - 80, height: Dimensions.get('screen').width - 90 }}>
                    {(this.props.forcazioni == false)
                        &&
                        <>
                        <View style={[styles.counterPosition, { top: (this.state.deviceType == 'TABLET') ? 42 : 6, right: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width) / 2 : (Dimensions.get('screen').width - 75) / 2 }]}>
                            {(this.props.stateDataSuperiore.includes(11))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(11) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>11</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(11)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>11</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (this.state.deviceType == 'TABLET') ? 91 : 20, right: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 185) / 2 : (Dimensions.get('screen').width) / 2 }]}>
                            {(this.props.stateDataSuperiore.includes(12))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(12) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>12</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(12)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>12</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (this.state.deviceType == 'TABLET') ? 150 : 51, right: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 315) / 2 : (Dimensions.get('screen').width + 60) / 2 }]}>
                            {(this.props.stateDataSuperiore.includes(13))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(13) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>13</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(13)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>13</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (this.state.deviceType == 'TABLET') ? 225 : 81, right: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 410) / 2 : (Dimensions.get('screen').width + 105) / 2 }]}>
                            {(this.props.stateDataSuperiore.includes(14))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(14) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>14</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(14)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>14</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        </>
                    }
                    <View style={[styles.counterPosition, { top: (this.state.deviceType == 'TABLET') ? 300 : 111, right: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 489) / 2 : (Dimensions.get('screen').width + 135) / 2 }]}>
                        {(this.props.stateDataSuperiore.includes(15))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(15) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>15</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(15)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>15</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (this.state.deviceType == 'TABLET') ? 385 : 141, right: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 547) / 2 : (Dimensions.get('screen').width + 160) / 2 }]}>
                        {(this.props.stateDataSuperiore.includes(16))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(16) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>16</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(16)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>16</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (this.state.deviceType == 'TABLET') ? 460 : 176, right: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 565) / 2 : (Dimensions.get('screen').width + 171) / 2 }]}>
                        {(this.props.stateDataSuperiore.includes(17))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(17) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>17</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(17)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>17</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (this.state.deviceType == 'TABLET') ? 545 : 211, right: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 565) / 2 : (Dimensions.get('screen').width + 171) / 2 }]}>
                        {(this.props.stateDataSuperiore.includes(18))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(18) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>18</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(18)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>18</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    {(this.props.forcazioni == false)
                        &&
                        <>
                        <View style={[styles.counterPosition, { top: (this.state.deviceType == 'TABLET') ? 42 : 6, left: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width) / 2 : (Dimensions.get('screen').width - 75) / 2 }]}>
                            {(this.props.stateDataSuperiore.includes(21))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(21) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>21</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(21)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>21</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (this.state.deviceType == 'TABLET') ? 91 : 20, left: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 185) / 2 : (Dimensions.get('screen').width) / 2 }]}>
                            {(this.props.stateDataSuperiore.includes(22))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(22) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>22</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(22)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>22</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (this.state.deviceType == 'TABLET') ? 150 : 51, left: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 315) / 2 : (Dimensions.get('screen').width + 60) / 2 }]}>
                            {(this.props.stateDataSuperiore.includes(23))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(23) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>23</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(23)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>23</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { top: (this.state.deviceType == 'TABLET') ? 225 : 81, left: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 410) / 2 : (Dimensions.get('screen').width + 105) / 2 }]}>
                            {(this.props.stateDataSuperiore.includes(24))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(24) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>24</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(24)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>24</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        </>
                    }
                    <View style={[styles.counterPosition, { top: (this.state.deviceType == 'TABLET') ? 300 : 111, left: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 489) / 2 : (Dimensions.get('screen').width + 135) / 2 }]}>
                        {(this.props.stateDataSuperiore.includes(25))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(25) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>25</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(25)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>25</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (this.state.deviceType == 'TABLET') ? 385 : 141, left: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 547) / 2 : (Dimensions.get('screen').width + 160) / 2 }]}>
                        {(this.props.stateDataSuperiore.includes(26))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(26) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>26</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(26)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>26</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (this.state.deviceType == 'TABLET') ? 460 : 176, left: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 565) / 2 : (Dimensions.get('screen').width + 171) / 2 }]}>
                        {(this.props.stateDataSuperiore.includes(27))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(27) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>27</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(27)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>27</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { top: (this.state.deviceType == 'TABLET') ? 545 : 211, left: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 565) / 2 : (Dimensions.get('screen').width + 171) / 2 }]}>
                        {(this.props.stateDataSuperiore.includes(28))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotSuperore == false || this.props.edentuliaTotSuperore == undefined) ? () => this.props.removeFunctionSuperiore(28) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>28</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionSuperiore(28)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>28</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    {(this.state.deviceType == 'TABLET')
                        ?
                        <Image source={(this.props.forcazioni == true) ? require('../assets/dentatura-superiore-deselezione.png') : require('../assets/dentatura-superiore.png')} resizeMode='contain' style={{ width: Dimensions.get('screen').width - 200, height: Dimensions.get('screen').width - 200 }} />
                        :
                        <Image source={(this.props.forcazioni == true) ? require('../assets/dentatura-superiore-deselezione.png') : require('../assets/dentatura-superiore.png')} resizeMode='contain' style={{ width: Dimensions.get('screen').width - 120, height: Dimensions.get('screen').width - 120 }} />
                    }
                </View>
            </ViewShot>
                {(this.props.edentulia == true)
                    &&
                    <View style={{ alignItems: 'center' }}>
                        <TouchableOpacity style={[styles.headImage, { justifyContent: 'center' }]} onPress={this.props.functionEdentuliaSuperiore}>
                            <AntDesign name={(this.props.edentuliaTotSuperore) ? 'checkcircle' : 'minuscircleo'} size={25} color={(this.props.edentuliaTotSuperore) ? '#f73939' : '#000'} />
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginLeft: 10 }}>Edentulia totale</Text>
                        </TouchableOpacity>
                    </View>
                }
            <ViewShot ref={this.props.refInferiore} style={{ alignItems: 'center', backgroundColor: '#fff' }} options={{ format: 'jpg', quality: 1.0 }}>
                <View style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff', width: Dimensions.get('screen').width - 80, height: Dimensions.get('screen').width - 90 }}>
                    <View style={[styles.counterPosition, { bottom: (this.state.deviceType == 'TABLET') ? 515 : (Platform.OS == 'android') ? 211 : 196, right: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 565) / 2 : (Dimensions.get('screen').width + 171) / 2 }]}>
                        {(this.props.stateDataInferiore.includes(48))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(48) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>48</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionInferiore(48)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>48</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { bottom: (this.state.deviceType == 'TABLET') ? 432 : (Platform.OS == 'android') ? 176 : 161, right: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 565) / 2 : (Dimensions.get('screen').width + 171) / 2 }]}>
                        {(this.props.stateDataInferiore.includes(47))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(47) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>47</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionInferiore(47)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>47</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { bottom: (this.state.deviceType == 'TABLET') ? 343 : (Platform.OS == 'android') ? 136 : 126, right: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 537) / 2 : (Dimensions.get('screen').width + 160) / 2 }]}>
                        {(this.props.stateDataInferiore.includes(46))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(46) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>46</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionInferiore(46)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>46</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { bottom: (this.state.deviceType == 'TABLET') ? 231 : 96, right: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 469) / 2 : (Dimensions.get('screen').width + 135) / 2 }]}>
                        {(this.props.stateDataInferiore.includes(45))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(45) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>45</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionInferiore(45)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>45</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    {(this.props.forcazioni == false)
                        &&
                        <>
                        <View style={[styles.counterPosition, { bottom: (this.state.deviceType == 'TABLET') ? 153 : (Platform.OS == 'android') ? 66 : 66, right: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 380) / 2 : (Dimensions.get('screen').width + 105) / 2 }]}>
                            {(this.props.stateDataInferiore.includes(44))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(44) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>44</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionInferiore(44)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>44</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { bottom: (this.state.deviceType == 'TABLET') ? 95 : (Platform.OS == 'android') ? 41 : 36, right: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 252) / 2 : (Dimensions.get('screen').width + 60) / 2 }]}>
                            {(this.props.stateDataInferiore.includes(43))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(43) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>43</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionInferiore(43)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>43</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { bottom: (this.state.deviceType == 'TABLET') ? 55 : 20, right: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 89) / 2 : (Dimensions.get('screen').width) / 2 }]}>
                            {(this.props.stateDataInferiore.includes(42))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(42) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>42</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionInferiore(42)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>42</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { bottom: (this.state.deviceType == 'TABLET') ? 42 : 9, right: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width - 65) / 2 : (Dimensions.get('screen').width - 75) / 2 }]}>
                            {(this.props.stateDataInferiore.includes(41))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(41) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>41</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionInferiore(41)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>41</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        </>
                    }
                    <View style={[styles.counterPosition, { bottom: (this.state.deviceType == 'TABLET') ? 515 : (Platform.OS == 'android') ? 211 : 196, left: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 565) / 2 : (Dimensions.get('screen').width + 171) / 2 }]}>
                        {(this.props.stateDataInferiore.includes(38))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(38) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>38</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionInferiore(38)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>38</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { bottom: (this.state.deviceType == 'TABLET') ? 432 : (Platform.OS == 'android') ? 176 : 161, left: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 565) / 2 : (Dimensions.get('screen').width + 171) / 2 }]}>
                        {(this.props.stateDataInferiore.includes(37))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(37) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>37</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionInferiore(37)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>37</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { bottom: (this.state.deviceType == 'TABLET') ? 343 : (Platform.OS == 'android') ? 136 : 126, left: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 537) / 2 : (Dimensions.get('screen').width + 160) / 2 }]}>
                        {(this.props.stateDataInferiore.includes(36))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(36) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>36</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionInferiore(36)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>36</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.counterPosition, { bottom: (this.state.deviceType == 'TABLET') ? 231 : 96, left: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 469) / 2 : (Dimensions.get('screen').width + 135) / 2 }]}>
                        {(this.props.stateDataInferiore.includes(35))
                            ?
                            <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(35) : null}>
                                <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>35</Text>
                                </View>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity onPress={() => this.props.addFunctionInferiore(35)}>
                                <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>35</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    {(this.props.forcazioni == false)
                        &&
                        <>
                        <View style={[styles.counterPosition, {  bottom: (this.state.deviceType == 'TABLET') ? 153 : (Platform.OS == 'android') ? 66 : 66, left: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 380) / 2 : (Dimensions.get('screen').width + 105) / 2 }]}>
                            {(this.props.stateDataInferiore.includes(34))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(34) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>34</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionInferiore(34)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>34</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { bottom: (this.state.deviceType == 'TABLET') ? 95 : (Platform.OS == 'android') ? 41 : 36, left: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 252) / 2 : (Dimensions.get('screen').width + 60) / 2 }]}>
                            {(this.props.stateDataInferiore.includes(33))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(33) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>33</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionInferiore(33)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>33</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { bottom: (this.state.deviceType == 'TABLET') ? 55 : 20, left: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width + 89) / 2 : (Dimensions.get('screen').width) / 2 }]}>
                            {(this.props.stateDataInferiore.includes(32))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(32) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>32</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionInferiore(32)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>32</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        <View style={[styles.counterPosition, { bottom: (this.state.deviceType == 'TABLET') ? 42 : 9, left: (this.state.deviceType == 'TABLET') ? (Dimensions.get('screen').width - 65) / 2 : (Dimensions.get('screen').width - 75) / 2 }]}>
                            {(this.props.stateDataInferiore.includes(31))
                                ?
                                <TouchableOpacity onPress={(this.props.edentuliaTotInferiore == false || this.props.edentuliaTotInferiore == undefined) ? () => this.props.removeFunctionInferiore(31) : null}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#f73939', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>31</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity onPress={() => this.props.addFunctionInferiore(31)}>
                                    <View style={[styles.counterTooth, { backgroundColor: '#0070FF', width: (this.state.deviceType == 'TABLET') ? 60 : 30, height: (this.state.deviceType == 'TABLET') ? 60 : 30, borderRadius: (this.state.deviceType == 'TABLET') ? 30 : 15 }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: (this.state.deviceType == 'TABLET') ? 25 : 14, color: '#fff' }}>31</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                        </View>
                        </>
                    }
                    {(this.state.deviceType == 'TABLET')
                        ?
                        <Image source={(this.props.forcazioni == true) ? require('../assets/dentatura-inferiore-deselezione.png') : require('../assets/dentatura-inferiore.png')} resizeMode='contain' style={{ width: Dimensions.get('screen').width - 200, height: Dimensions.get('screen').width - 200 }} />
                        :
                        <Image source={(this.props.forcazioni == true) ? require('../assets/dentatura-inferiore-deselezione.png') : require('../assets/dentatura-inferiore.png')} resizeMode='contain' style={{ width: Dimensions.get('screen').width - 120, height: Dimensions.get('screen').width - 120 }} />
                    }
                </View>
            </ViewShot>
                {(this.props.edentulia == true)
                    &&
                    <View style={{ alignItems: 'center', marginTop: 30 }}>
                        <TouchableOpacity style={[styles.headImage, { justifyContent: 'center' }]} onPress={this.props.functionEdentuliaInferiore}>
                            <AntDesign name={(this.props.edentuliaTotInferiore) ? 'checkcircle' : 'minuscircleo'} size={25} color={(this.props.edentuliaTotInferiore) ? '#f73939' : '#000'} />
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginLeft: 10 }}>Edentulia totale</Text>
                        </TouchableOpacity>
                    </View>
                }
            </>
        )
    }
}

const styles = StyleSheet.create({
    headImage: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    counterTooth: {
        width: 30,
        height: 30,
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#0070FF'
    },
    counterPosition: {
        position: 'absolute',
        zIndex: 2
    }
});