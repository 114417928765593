import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Switch, Dimensions, Alert, Modal, TouchableOpacity as TouchButton, TouchableOpacity, TextInput, Platform, FlatList, Image } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather, AntDesign } from '@expo/vector-icons';
import RNPickerSelect from 'react-native-picker-select';
import PhoneInput from "react-native-phone-number-input";
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Moment from 'moment';
import 'moment/locale/it';
import DateTimePicker from '@react-native-community/datetimepicker';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import { MaskedTextInput } from "react-native-mask-text";
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class RichiamiScreen extends React.Component {
    constructor(props) {
        super(props)
        this.searchInput = React.createRef()
        this.searchPaziente = React.createRef()
        this.state = {
            loading: true,
            loadingData: false,
            listRichiami: [],
            storyStudi: [],
            showModal: false,
            cerca: '',
            searchResult: [],
            cercaRichiamo: '',
            loadingSearch: false,
            page: 1,
            isLoading: false,
            loadingFinish: false,
            isFiltri: false,
            image: '',
            idPaziente: '',
            dataIgene: '',
            esito: '',
            cap: '',
            descrizione: '',
            idRichiamo: '',
            loadingActive: false,
            isEdit: false,
            cercaPaziente: '',
            loadingPaziente: false,
            searchResultPaziente: [],
            widthTitle: 0,
            cercaData: false,
            ordinamento: 'DESC',
            automazione: {},
            selectPaziente: null
        }
    }

    componentDidMount() {
        this.checkAuth()
        this.getAutomazione('68')
        this.getRichiami()
    }

    getAutomazione = (id_automazione) => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_automazione', id_automazione);
        axios.post('https://clinicautomation.it/app/get_automazione.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                this.setState({ automazione: {
                    descrizione: json.descrizione,
                    id: json.id,
                    nome: json.nome,
                    url: json.url
                } })
            } else {
                this.setState({ automazione: {} })
            }
        }).catch((error) => {
            this.setState({ automazione: {} })
            console.error(error);
        })
    }

    getDataMessages = async (id_automazione) => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
    
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('id_automazione', id_automazione);
    
        try {
            let response = await axios.post(
                'https://clinicautomation.it/app/get_impostazioni_automazioni.php',
                formData,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );
            
            // I dati JSON sono direttamente accessibili nella proprietà `data` dell'oggetto response
            let json = response.data;
    
            // Chiamata per ottenere i dati di default
            let formDataDefault = new FormData();
            formDataDefault.append('token', token);
            formDataDefault.append('id_automazione', id_automazione);

            let responseDefault = await axios.post(
                'https://clinicautomation.it/app/get_default_messages.php',
                formDataDefault,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );
            
            // I dati JSON sono direttamente accessibili nella proprietà `data` dell'oggetto response
            let jsonDefault = responseDefault.data;
    
            // Controlla se list è false
            if (!jsonDefault.list) {
                return {}; // Restituisci un oggetto vuoto se list è false
            }
    
            // Funzione di utilità per popolare i dati
            const populateField = (defaultValue, userValue) => {
                return userValue && userValue !== "" ? userValue : defaultValue;
            };
    
            const result = {
                activeAppuntamento: (json.active_appuntamento == 1) ? true : (json.active_appuntamento == 0) ? false : (jsonDefault.active_appuntamento) == 1 ? true : false,
                messaggioAppuntamento: jsonDefault.active_appuntamento == 1 ? populateField(jsonDefault.message_appuntamento, json.active_appuntamento == 1 ? json.message_appuntamento : "") : "",
                appuntamentoTitle: jsonDefault.active_appuntamento == 1 ? populateField(jsonDefault.appuntamento_title, json.active_appuntamento == 1 ? json.appuntamento_title : "") : "",
    
                activeReminder1: (json.active_reminder_1 == 1) ? true : (json.active_reminder_1 == 0) ? false : (jsonDefault.active_reminder_1) == 1 ? true : false,
                messaggioReminder1: jsonDefault.active_reminder_1 == 1 ? populateField(jsonDefault.message_reminder_1, json.active_reminder_1 == 1 ? json.message_reminder_1 : "") : "",
                reminder1Title: jsonDefault.active_reminder_1 == 1 ? populateField(jsonDefault.reminder_1_title, json.active_reminder_1 == 1 ? json.reminder_1_title : "") : "",
                giornoReminder1: jsonDefault.active_reminder_1 == 1 ? populateField(jsonDefault.giorno_reminder_1, json.active_reminder_1 == 1 ? json.giorno_reminder_1 : "") : "",
    
                activeReminder2: (json.active_reminder_2 == 1) ? true : (json.active_reminder_2 == 0) ? false : (jsonDefault.active_reminder_2) == 1 ? true : false,
                messaggioReminder2: jsonDefault.active_reminder_2 == 1 ? populateField(jsonDefault.message_reminder_2, json.active_reminder_2 == 1 ? json.message_reminder_2 : "") : "",
                reminder2Title: jsonDefault.active_reminder_2 == 1 ? populateField(jsonDefault.reminder_2_title, json.active_reminder_2 == 1 ? json.reminder_2_title : "") : "",
                giornoReminder2: jsonDefault.active_reminder_2 == 1 ? populateField(jsonDefault.giorno_reminder_2, json.active_reminder_2 == 1 ? json.giorno_reminder_2 : "") : "",
    
                activeFollowup1: (json.active_followup_1 == 1) ? true : (json.active_followup_1 == 0) ? false : (jsonDefault.active_followup_1) == 1 ? true : false,
                messaggioFollowup1: jsonDefault.active_followup_1 == 1 ? populateField(jsonDefault.message_followup_1, json.active_followup_1 == 1 ? json.message_followup_1 : "") : "",
                followup1Title: jsonDefault.active_followup_1 == 1 ? populateField(jsonDefault.followup_1_title, json.active_followup_1 == 1 ? json.followup_1_title : "") : "",
                giornoFollowup1: jsonDefault.active_followup_1 == 1 ? populateField(jsonDefault.giorno_followup_1, json.active_followup_1 == 1 ? json.giorno_followup_1 : "") : "",
    
                activeFollowup2: (json.active_followup_2 == 1) ? true : (json.active_followup_2 == 0) ? false : (jsonDefault.active_followup_2) == 1 ? true : false,
                messaggioFollowup2: jsonDefault.active_followup_2 == 1 ? populateField(jsonDefault.message_followup_2, json.active_followup_2 == 1 ? json.message_followup_2 : "") : "",
                followup2Title: jsonDefault.active_followup_2 == 1 ? populateField(jsonDefault.followup_2_title, json.active_followup_2 == 1 ? json.followup_2_title : "") : "",
                giornoFollowup2: jsonDefault.active_followup_2 == 1 ? populateField(jsonDefault.giorno_followup_2, json.active_followup_2 == 1 ? json.giorno_followup_2 : "") : "",
    
                activeFollowup3: (json.active_followup_3 == 1) ? true : (json.active_followup_3 == 0) ? false : (jsonDefault.active_followup_3) == 1 ? true : false,
                messaggioFollowup3: jsonDefault.active_followup_3 == 1 ? populateField(jsonDefault.message_followup_3, json.active_followup_3 == 1 ? json.message_followup_3 : "") : "",
                followup3Title: jsonDefault.active_followup_3 == 1 ? populateField(jsonDefault.followup_3_title, json.active_followup_3 == 1 ? json.followup_3_title : "") : "",
                giornoFollowup3: jsonDefault.active_followup_3 == 1 ? populateField(jsonDefault.giorno_followup_3, json.active_followup_3 == 1 ? json.giorno_followup_3 : "") : "",
    
                activeFollowup4: (json.active_followup_4 == 1) ? true : (json.active_followup_4 == 0) ? false : (jsonDefault.active_followup_4) == 1 ? true : false,
                messaggioFollowup4: jsonDefault.active_followup_4 == 1 ? populateField(jsonDefault.message_followup_4, json.active_followup_4 == 1 ? json.message_followup_4 : "") : "",
                followup4Title: jsonDefault.active_followup_4 == 1 ? populateField(jsonDefault.followup_4_title, json.active_followup_4 == 1 ? json.followup_4_title : "") : "",
                giornoFollowup4: jsonDefault.active_followup_4 == 1 ? populateField(jsonDefault.giorno_followup_4, json.active_followup_4 == 1 ? json.giorno_followup_4 : "") : "",
            };
    
            return result;
        } catch (error) {
            console.error("Errore nella fetch dei dati:", error);
            return {}; // Restituisci un oggetto vuoto in caso di errore
        }
    }

    getUrlMessages = (jsonData, nomeCliente, nomeStudio, dataAppuntamento) => {
        let urlScheme = "";
    
        if (jsonData.activeAppuntamento == true) {
            if (jsonData.messaggioAppuntamento !== null && jsonData.messaggioAppuntamento !== "") {
                const messaggioAppuntamento = jsonData.messaggioAppuntamento
                    .replace('%TITLE%', jsonData.appuntamentoTitle)
                    .replace(/(\r\n|\n|\r)/g, '\\n');
                urlScheme = `${urlScheme}&messaggio-appuntamento=${encodeURIComponent(messaggioAppuntamento)}`;
            }
        }
    
        if (jsonData.activeReminder1 == true) {
            if (jsonData.messaggioReminder1 !== null && jsonData.messaggioReminder1 !== "") {
                const messaggioReminder1 = jsonData.messaggioReminder1
                    .replace('%TITLE%', jsonData.reminder1Title)
                    .replace(/(\r\n|\n|\r)/g, '\\n');
                urlScheme = `${urlScheme}&messaggio-reminder-1=${encodeURIComponent(messaggioReminder1)}&giorno-reminder-1=${encodeURIComponent(jsonData.giornoReminder1)}`;
            }
        }
    
        if (jsonData.activeReminder2 == true) {
            if (jsonData.messaggioReminder2 !== null && jsonData.messaggioReminder2 !== "") {
                const messaggioReminder2 = jsonData.messaggioReminder2
                    .replace('%TITLE%', jsonData.reminder2Title)
                    .replace(/(\r\n|\n|\r)/g, '\\n');
                urlScheme = `${urlScheme}&messaggio-reminder-2=${encodeURIComponent(messaggioReminder2)}&giorno-reminder-2=${encodeURIComponent(jsonData.giornoReminder2)}`;
            }
        }
    
        if (jsonData.activeFollowup1 == true) {
            if (jsonData.messaggioFollowup1 !== null && jsonData.messaggioFollowup1 !== "") {
                const messaggioFollowup1 = jsonData.messaggioFollowup1
                    .replace('%TITLE%', jsonData.followup1Title)
                    .replace(/(\r\n|\n|\r)/g, '\\n');
                urlScheme = `${urlScheme}&messaggio-followup-1=${encodeURIComponent(messaggioFollowup1)}&giorno-followup-1=${encodeURIComponent(jsonData.giornoFollowup1)}`;
            }
        }
    
        if (jsonData.activeFollowup2 == true) {
            if (jsonData.messaggioFollowup2 !== null && jsonData.messaggioFollowup2 !== "") {
                const messaggioFollowup2 = jsonData.messaggioFollowup2
                    .replace('%TITLE%', jsonData.followup2Title)
                    .replace(/(\r\n|\n|\r)/g, '\\n');
                urlScheme = `${urlScheme}&messaggio-followup-2=${encodeURIComponent(messaggioFollowup2)}&giorno-followup-2=${encodeURIComponent(jsonData.giornoFollowup2)}`;
            }
        }
    
        if (jsonData.activeFollowup3 == true) {
            if (jsonData.messaggioFollowup3 !== null && jsonData.messaggioFollowup3 !== "") {
                const messaggioFollowup3 = jsonData.messaggioFollowup3
                    .replace('%TITLE%', jsonData.followup3Title)
                    .replace(/(\r\n|\n|\r)/g, '\\n');
                urlScheme = `${urlScheme}&messaggio-followup-3=${encodeURIComponent(messaggioFollowup3)}&giorno-followup-3=${encodeURIComponent(jsonData.giornoFollowup3)}`;
            }
        }
    
        if (jsonData.activeFollowup4 == true) {
            if (jsonData.messaggioFollowup4 !== null && jsonData.messaggioFollowup4 !== "") {
                const messaggioFollowup4 = jsonData.messaggioFollowup4
                    /*.replace('%NOME%', nomeCliente)
                    .replace('%STUDIO%', nomeStudio)
                    .replace('%DATA%', dataAppuntamento)*/
                    .replace('%TITLE%', jsonData.followup4Title)
                    .replace(/(\r\n|\n|\r)/g, '\\n');
                urlScheme = `${urlScheme}&messaggio-followup-4=${encodeURIComponent(messaggioFollowup4)}&giorno-followup-4=${encodeURIComponent(jsonData.giornoFollowup4)}`;
            }
        }
    
        return urlScheme;
    }

    checkAuth = async () => {
        const id = await AsyncStorage.getItem('id')
        if (id != null && id != undefined && id != '') {

        } else {
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'Login' }],
            })
        }
    }

    getRichiami = async () => {
        let listRichiami = this.state.listRichiami;
        const id = await AsyncStorage.getItem('id')
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', this.state.page)
        formData.append('ordinamento', this.state.ordinamento)
        axios.post('https://clinicautomation.it/app/get_richiami.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            this.setState({ loading: false })
            if (json.length > 0) {
                listRichiami = [...listRichiami, ...json];
                this.setState({ listRichiami: listRichiami, isLoading: false })
            } else {
                this.setState({ isLoading: false, loadingFinish: true })
            }
        }).catch((error) => {
            this.setState({ loading: false, isLoading: false })
            console.error(error);
        })
    }

    searchRichiami = async (search) => {
        let searchItem = '';
        searchItem = search;
        /*if (this.state.cercaData) {
            searchItem = `${search.split('/')[2]}-${search.split('/')[1]}-${search.split('/')[0]}`;
        } else {
            searchItem = search;
        }*/
        this.setState({ cercaRichiamo: search, loadingSearch: true, loading: true, page: 1 })
        const id = await AsyncStorage.getItem('id')
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('search_item', searchItem);
        formData.append('page', 1);
        formData.append('ordinamento', this.state.ordinamento);
        axios.post('https://clinicautomation.it/app/search_richiami.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            this.setState({ loadingSearch: false, loading: false, isFiltri: true })
            if (json.length > 0) {
                this.setState({ listRichiami: json })
            } else {
                this.setState({ listRichiami: [] })
            }
        }).catch((error) => {
            this.setState({ loadingSearch: false, loading: false })
            console.error(error);
        })
    }

    searchRichiamiContinue = async () => {
        let listRichiami = this.state.listRichiami;
        let searchItem = '';
        searchItem = this.state.cercaRichiamo;
        /*if (this.state.cercaData) {
            searchItem = `${this.state.cercaRichiamo.split('/')[2]}-${this.state.cercaRichiamo.split('/')[1]}-${this.state.cercaRichiamo.split('/')[0]}`;
        } else {
            searchItem = this.state.cercaRichiamo;
        }*/
        const id = await AsyncStorage.getItem('id')
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('search_item', searchItem);
        formData.append('page', this.state.page);
        formData.append('ordinamento', this.state.ordinamento);
        axios.post('https://clinicautomation.it/app/search_richiami.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                listRichiami = [...listRichiami, ...json];
                this.setState({ listRichiami: listRichiami, isLoading: false })
            } else {
                this.setState({ isLoading: false, loadingFinish: true })
            }
        }).catch((error) => {
            this.setState({ loading: false, isLoading: false })
            console.error(error);
        })
    }

    handleDeleteRequest = (user, index, item) => {
        if (confirm("Attenzione! Sei sicuro di voler eliminare il richiamo?") == true) {
            this.handleDelete(user, index, item)
        }
    }

    handleDelete = async (user, index, item) => {
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        const idRichiamo = user;
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id', user);
        axios.post('https://clinicautomation.it/app/delete_richiamo.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                const resultMessages = await this.getDataMessages('68')
                if (Object.keys(resultMessages).length === 0) {
                    const listRichiami = this.state.listRichiami;
                    listRichiami.splice(index, 1)
                    this.setState({ listRichiami })
                } else {
                    if (Object.keys(this.state.automazione).length === 0) {
                        const listRichiami = this.state.listRichiami;
                        listRichiami.splice(index, 1)
                        this.setState({ listRichiami })
                    } else {
                        const idStudio = await AsyncStorage.getItem('id')
                        const telefonoStudio = await AsyncStorage.getItem('telefono')
                        const country_code_studio = JSON.parse(await AsyncStorage.getItem('country_code'))
                        const via = await AsyncStorage.getItem('via')
                        const citta = await AsyncStorage.getItem('citta')
                        const nomeStudio = await AsyncStorage.getItem('nome_studio')
                        const country_code = JSON.parse(item.country_code).callingCode[0];
                        const urlScheme = this.getUrlMessages(resultMessages, `${item.nome} ${item.cognome}`, nomeStudio, '')
                        fetch(`${this.state.automazione.url}?idPaziente=${item.id_paziente}&name=${item.nome}&surname=${item.cognome}&phone=${country_code}${item.telefono}&email=${item.email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code_studio.callingCode[0]}${telefonoStudio}&id-studio=${idStudio}&status=ELIMINATO&idrichiamo=${idRichiamo}&idautomazione=${this.state.automazione.id}&nomeautomazione=${this.state.automazione.nome}${urlScheme}`, {
                            method: 'GET',
                            headers: {
                                Accept: 'application/json'
                            }
                        })
                        .then((response) => response.text())
                        .then(async (element) => {
                            const listRichiami = this.state.listRichiami;
                            listRichiami.splice(index, 1)
                            this.setState({ listRichiami })
                        }).catch((error) => {
                            const listRichiami = this.state.listRichiami;
                            listRichiami.splice(index, 1)
                            this.setState({ listRichiami })
                            console.error(error);
                        })
                    }
                }
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    handleAddRichiamo = async () => {
        if (this.state.idPaziente !== '') {
            this.setState({ loadingActive: true })
            const id = await AsyncStorage.getItem('id')
            const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_user', id);
            formData.append('id_paziente', this.state.idPaziente);
            formData.append('data_igene', (this.state.dataIgene !== null && this.state.dataIgene !== '') ? Moment(new Date(`${this.state.dataIgene.split('/')[2]}-${this.state.dataIgene.split('/')[1]}-${this.state.dataIgene.split('/')[0]}`)).format('YYYY-MM-DD') : '');
            axios.post('https://clinicautomation.it/app/add_richiamo.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    const resultMessages = await this.getDataMessages('68')
                    if (Object.keys(resultMessages).length === 0) {
                        this.resetListPage()
                        this.setState({ loadingActive: false, showModal: false, isEdit: false, idPaziente: '', selectPaziente: null, dataIgene: '', cercaPaziente: '' })
                    } else {
                        if (Object.keys(this.state.automazione).length === 0) {
                            this.resetListPage()
                            this.setState({ loadingActive: false, showModal: false, isEdit: false, idPaziente: '', selectPaziente: null, dataIgene: '', cercaPaziente: '' })
                        } else {
                            const idStudio = await AsyncStorage.getItem('id')
                            const telefonoStudio = await AsyncStorage.getItem('telefono')
                            const country_code_studio = JSON.parse(await AsyncStorage.getItem('country_code'))
                            const via = await AsyncStorage.getItem('via')
                            const citta = await AsyncStorage.getItem('citta')
                            const nomeStudio = await AsyncStorage.getItem('nome_studio')
                            const country_code = this.state.selectPaziente.country_code.callingCode[0];
                            const urlScheme = this.getUrlMessages(resultMessages, `${this.state.selectPaziente.nome} ${this.state.selectPaziente.cognome}`, nomeStudio, '')
                            fetch(`${this.state.automazione.url}?idPaziente=${this.state.idPaziente}&name=${this.state.selectPaziente.nome}&surname=${this.state.selectPaziente.cognome}&phone=${country_code}${this.state.selectPaziente.telefono}&email=${this.state.selectPaziente.email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code_studio.callingCode[0]}${telefonoStudio}&id-studio=${idStudio}&status=CREATO&idrichiamo=${json.id_richiamo}&idautomazione=${this.state.automazione.id}&nomeautomazione=${this.state.automazione.nome}${urlScheme}`, {
                                method: 'GET',
                                headers: {
                                    Accept: 'application/json'
                                }
                            })
                            .then((response) => response.text())
                            .then(async (element) => {
                                this.resetListPage()
                                this.setState({ loadingActive: false, showModal: false, isEdit: false, idPaziente: '', selectPaziente: null, dataIgene: '', cercaPaziente: '' })
                            }).catch((error) => {
                                this.resetListPage()
                                this.setState({ loadingActive: false, showModal: false, isEdit: false, idPaziente: '', selectPaziente: null, dataIgene: '', cercaPaziente: '' })
                                console.error(error);
                            })
                        }
                    }
                } else {
                    this.setState({ loadingActive: false })
                    alert(`Attenzione! ${json.message}`)
                }
            }).catch((error) => {
                this.setState({ loadingActive: false })
                console.error(error);
            })
        } else {
            alert(`Attenzione! Compila tutti i campi obbligatori`)
        }
    }

    handleEditRichiamo = async () => {
        if (this.state.idRichiamo !== '' && this.state.idPaziente !== '' && this.state.esito !== null) {
            this.setState({ loadingActive: true })
            const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_richiamo', this.state.idRichiamo);
            formData.append('id_paziente', this.state.idPaziente);
            formData.append('data_igene', (this.state.dataIgene !== null && this.state.dataIgene !== '') ? Moment(new Date(`${this.state.dataIgene.split('/')[2]}-${this.state.dataIgene.split('/')[1]}-${this.state.dataIgene.split('/')[0]}`)).format('YYYY-MM-DD') : '');
            formData.append('esito', this.state.esito);
            axios.post('https://clinicautomation.it/app/edit_richiamo.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    this.resetListPage()
                    this.setState({ loadingActive: false, showModal: false, isEdit: false, idPaziente: '', dataIgene: '', esito: '', cercaPaziente: '' })
                    alert(`Richiamo aggioranto! ${json.message}`)
                } else {
                    this.setState({ loadingActive: false })
                    alert(`Attenzione! ${json.message}`)
                }
            }).catch((error) => {
                this.setState({ loadingActive: false })
                console.error(error);
            })
        } else {
            alert(`Attenzione! Compila tutti i campi obbligatori`)
        }
    }

    isComplexJSON = (input) => {
        try {
            const parsed = JSON.parse(input);
            // Controlla se è un oggetto (tipo JSON complesso)
            return typeof parsed === 'object' && parsed !== null;
        } catch (error) {
            // Se fallisce il parsing, è solo una stringa semplice
            return false;
        }
    }

    renderItem = ({item, index}) => {
        const isEven = index % 2 === 0;
        const country_code = JSON.parse(item.country_code).callingCode[0];
        return(
            <View key={index} style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: 10, justifyContent: 'space-between', marginBottom: 15, marginRight: (!isEven) ? 0 : '6%' }]}>
                <View style={{ width: (Dimensions.get('screen').width <= 500) ? '65%' : 'auto' }}>
                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>{item.nome} {item.cognome}</Text>
                    {(item.email !== null && item.email !== "")
                        &&
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, color: 'grey' }}>{item.email}</Text>
                    }
                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, color: 'grey' }}>+{country_code}{item.telefono}</Text>
                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, color: (item.active == "1" && item.active == 1) ? 'green' : 'red' }}>{(item.active == "1" || item.active == 1) ? 'Attivo' : 'Disattivo'}</Text>
                    {/*(item.data_igene !== null && item.data_igene !== "")
                        &&
                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 12 }}>Data prox. appuntamento: {Moment(item.data_igene).format('DD/MM/YYYY')}</Text>
                    */}
                    {/*<Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Esito: {(item.esito == 1 || item.esito == "1") ? 'Scaduto' : 'In corso'}</Text>*/}
                    {/*(item.data_esito !== null && item.data_esito !== "" && (item.esito == 1 || item.esito == "1"))
                        &&
                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Scade il {Moment(item.data_esito).format('DD/MM/YYYY')}</Text>
                    */}
                </View>
                <View>
                    {/*<TouchableOpacity style={[styles.buttonMore, { marginBottom: 8 }]} onPress={() => this.setState({ isEdit: true, idRichiamo: item.id, idPaziente: item.id_paziente, dataIgene: Moment(item.data_igene).format('DD/MM/YYYY'), esito: item.esito, cercaPaziente: `${item.nome} ${item.cognome}`, showModal: true })}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Modifica</Text>
                    </TouchableOpacity>*/}
                    {(item.disable !== 1 && item.disable !== "1")
                        ?
                        <TouchableOpacity style={[styles.buttonMore, { backgroundColor: '#fc3535' }]} onPress={() => this.handleDeleteRequest(item.id, index, item)}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Elimina</Text>
                        </TouchableOpacity>
                        :
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, textAlign: 'right' }}>Appuntamento{'\n'}creato</Text>
                    }
                </View>
            </View>
        )
    }

    renderFooter = () => {
        return(
            this.state.isLoading && !this.state.loadingFinish ?
            <View style={{ marginTop: 10, alignItems: 'center' }}>
                <ActivityIndicator size='small' color='#FFF' />
            </View> : null
        );
    }

    handleMore = () => {
        if (!this.state.isFiltri) {
            this.setState({ page: this.state.page + 1, isLoading: true })
            setTimeout(() => {
                this.getRichiami()
            }, 1000);
        } else {
            this.setState({ page: this.state.page + 1, isLoading: true })
            setTimeout(() => {
                this.searchRichiamiContinue()
            }, 1000);
        }
    }

    resetListPage = () => {
        this.setState({ page: 1, loading: true, listRichiami: [], isFiltri: false, loadingSearch: false, cercaRichiamo: '' })
        setTimeout(() => {
            this.getRichiami()
        }, 1000);
    }

    continuePatientsQueryLike = async (searchText) => {
        this.setState({ cercaPaziente: searchText, loadingPaziente: true })
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', 1);
        formData.append('search_item', searchText);
        axios.post('https://clinicautomation.it/app/search_pazienti.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ searchResultPaziente: json, loadingPaziente: false })
            } else {
                this.setState({ searchResultPaziente: [], loadingPaziente: false })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    selectPaziente = (uid, nome, cognome, item) => {
        this.setState({ 
            idPaziente: uid,
            searchResultPaziente: [],
            cercaPaziente: `${nome} ${cognome}`,
            selectPaziente: {
                cognome: item.cognome,
                country_code: JSON.parse(item.country_code), 
                data_creazione: item.data_creazione,
                email: item.email,
                figli: item.figli,
                fonte: item.fonte,
                id: item.id,
                impiego: item.impiego,
                nome: item.nome,
                paziente: item.paziente,
                sesso: item.sesso,
                telefono: item.telefono,
                valutazione: item.valutazione
            } 
        })
        this.searchPaziente.current.blur()
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('App'); // Schermata di fallback
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Remind</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.backButton} onPress={() => this.setState({ showModal: true, isEdit: false })}>
                        <AntDesign name='pluscircleo' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 7 }}>Nuovo remind</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ marginBottom: 25, paddingTop: 20, paddingHorizontal: 20, alignItems: 'center' }}>
                    <View style={[styles.containerTitle, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '40%' }]} onLayout={(event) => {
                        const {x, y, width, height} = event.nativeEvent.layout;
                        this.setState({ widthTitle: width })
                    }}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 20, color: '#0070FF' }}>PROGRAMMA REMIND</Text>
                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 13 }}>Gestisci lo scadenziario dei remind</Text>
                    </View>
                </View>
                <View style={{ paddingHorizontal: 20, alignItems: 'center' }}>
                    {/*<View style={{ alignItems: 'flex-end', marginBottom: 10 }}>
                        <TouchableOpacity style={[styles.buttonMore, { backgroundColor: (this.state.ordinamento == 'DESC') ? '#2484FF' : '#fff', width: 200, borderRadius: 0, borderTopLeftRadius: 18, borderTopRightRadius: 18 }]} onPress={() => { this.setState({ ordinamento: 'DESC' }); this.resetListPage() }}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: (this.state.ordinamento == 'DESC') ? '#fff' : '#0070FF' }}>
                                Data più recente
                            </Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.buttonMore, { backgroundColor: (this.state.ordinamento == 'ASC') ? '#2484FF' : '#fff', width: 200, borderRadius: 0, borderBottomLeftRadius: 18, borderBottomRightRadius: 18 }]} onPress={() => { this.setState({ ordinamento: 'ASC' }); this.resetListPage() }}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: (this.state.ordinamento == 'ASC') ? '#fff' : '#0070FF' }}>
                                Data meno recente
                            </Text>
                        </TouchableOpacity>
                    </View>*/}
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 15 }}>
                        <View style={styles.containerIcon}>
                            {(this.state.loadingSearch)
                                ?
                                <ActivityIndicator size='small' color='#000' />
                                :
                                <AntDesign name='search1' color='#000' size={23} />
                            }
                        </View>
                        {(this.state.cercaData)
                            ?
                            <MaskedTextInput
                                mask="99/99/9999"
                                value={this.state.cercaRichiamo}
                                onChangeText={(cerca) => this.searchRichiami(cerca)}
                                placeholderTextColor='#bfbfbf'
                                placeholder={`Es. ${Moment(new Date()).format('DD/MM/YYYY')}`}
                                style={[styles.input, { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, paddingLeft: 0, width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 160 : (this.state.widthTitle - 120), marginRight: 10, backgroundColor: '#FFF' }]}
                                keyboardType='number-pad'
                            />
                            :
                            <TextInput
                                style={[styles.input, { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, paddingLeft: 0, width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 160 : (this.state.widthTitle - 120), marginRight: 10, backgroundColor: '#FFF' }]}
                                placeholderTextColor='#D3D6D7'
                                placeholder='Cerca il remind'
                                value={this.state.cercaRichiamo}
                                onChangeText={(cerca) => this.searchRichiami(cerca)}
                            />
                        }
                        <TouchableOpacity disabled={(this.state.cercaRichiamo !== '') ? false : true} style={[styles.backButton, { backgroundColor: (this.state.cercaRichiamo !== '') ? '#FFF' : '#D3D6D7', width: 55, height: 55, paddingHorizontal: 0 }]} onPress={this.resetListPage}>
                            <AntDesign name='closecircleo' color={(this.state.cercaRichiamo !== '') ? '#0070FF' : 'grey'} size={23} />
                        </TouchableOpacity>
                    </View>
                </View>
                {(this.state.loading)
                    ?
                    <View style={{ paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150, paddingTop: 10, paddingBottom: Dimensions.get('screen').height / 4 }}>
                        <View style={[styles.buttonLink, { width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : Dimensions.get('screen').width - 300, paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'center' }]}>
                            <ActivityIndicator size='small' color='#0070FF' />
                        </View>
                    </View>
                    :
                    (this.state.listRichiami.length > 0)
                        ?
                        <FlatList
                            data={this.state.listRichiami}
                            renderItem={this.renderItem}
                            contentContainerStyle={{ 
                                paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150,
                                paddingTop: 10,
                                paddingBottom: Dimensions.get('screen').height / 4
                            }}
                            numColumns={(Dimensions.get('screen').width <= 500) ? 1 : 2}
                            keyExtractor={(item, index) => index.toString()}
                            showsVerticalScrollIndicator={false}
                            ListFooterComponent={this.renderFooter}
                            onEndReachedThreshold={0.5}
                            onEndReached={this.handleMore}
                        />
                        :
                        <View style={{ paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150, paddingTop: 10, paddingBottom: Dimensions.get('screen').height / 4 }}>
                            <View style={[styles.buttonLink, { width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : Dimensions.get('screen').width - 300, paddingHorizontal: 20, paddingVertical: 20 }]}>
                                <Feather name='alert-triangle' color='#fc3535' size={30} />
                                <View style={{ marginLeft: 15 }}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Non sono presenti remind</Text>
                                    <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Aggiungi il tuo primo remind</Text>
                                </View>
                            </View>
                        </View>
                }
                <View style={styles.positionCricle}>
                    <View style={styles.circle}></View>
                </View>
                <Modal
                    visible={this.state.showModal}
                    animationType='slide'
                    presentationStyle='pageSheet'
                    transparent={false}
                    onRequestClose={() => {
                        this.setState({ showModal: false, idPaziente: '', dataIgene: '', esito: '', cercaPaziente: '' })
                    }}
                >
                    <View style={[styles.container, { backgroundColor: '#FFF' }]}>
                        <View style={[styles.header, { paddingTop: 10 }]}>
                            <TouchButton style={styles.backButton} onPress={() => this.setState({ showModal: false, idPaziente: '', dataIgene: '', esito: '', cercaPaziente: '' })}>
                                <Feather name='arrow-left' color='#fff' size={23} />
                            </TouchButton>
                        </View>
                        <ScrollView showsVerticalScrollIndicator={false}>
                            <View style={[styles.containerCenter, { marginBottom: Dimensions.get('screen').height / 3 }]}>
                                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginRight: 7 }}>Seleziona il paziente*</Text>
                                    {(this.state.loadingPaziente)
                                        &&
                                        <ActivityIndicator size='small' color='#000' />
                                    }
                                </View>
                                <TextInput
                                    style={[styles.input, { borderBottomLeftRadius: (this.state.searchResultPaziente.length > 0) ? 0 : 10, borderBottomRightRadius: (this.state.searchResultPaziente.length > 0) ? 0 : 10 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Inserisci il nome del Paziente'c
                                    value={this.state.cercaPaziente}
                                    //onChangeText={(cercaPaziente) => {this.setState({ cercaPaziente }); this.filterResult(cercaPaziente)}}
                                    onChangeText={(cercaPaziente) => this.continuePatientsQueryLike(cercaPaziente)}
                                    ref={this.searchPaziente}
                                    //editable={(this.state.loadingData) ? false : true}
                                />
                                {(this.state.searchResultPaziente.length > 0)
                                    &&
                                    this.state.searchResultPaziente.map((item, index) => {
                                        let counter = index + 1;
                                        const country_code = JSON.parse(item.country_code)
                                        return(
                                            <TouchButton onPress={() => this.selectPaziente(item.id, item.nome.trim(), item.cognome.trim(), item)} key={item.id} style={[styles.listUser, {
                                                borderBottomLeftRadius: (counter == this.state.searchResultPaziente.length) ? 10 : 0,
                                                borderBottomRightRadius: (counter == this.state.searchResultPaziente.length) ? 10 : 0
                                            }]}>
                                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>
                                                    {item.nome.trim()} {item.cognome.trim()}{'\n'}
                                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14 }}>+{country_code.callingCode[0]}{item.telefono}</Text>
                                                </Text>
                                            </TouchButton>
                                        )
                                    })
                                }
                                {/*<Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10, marginTop: 20 }}>Data prox. appuntamento</Text>
                                <MaskedTextInput
                                    mask="99/99/9999"
                                    value={this.state.dataIgene}
                                    onChangeText={(dataIgene) => this.setState({ dataIgene })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder={`Es. ${Moment(new Date()).format('DD/MM/YYYY')}`}
                                    style={[styles.input, { marginBottom: 20 }]}
                                    keyboardType='number-pad'
                                />*/}
                                {(this.state.isEdit)
                                    &&
                                    <>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Esito*</Text>
                                    <RNPickerSelect
                                        placeholder={{ label: "Seleziona l'esito", value: null }}
                                        doneText='Fine'
                                        value={this.state.esito}
                                        style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                        onValueChange={(esito) => this.setState({ esito })}
                                        items={[
                                            { label: 'In corso', value: '0' },
                                            { label: 'Scaduto', value: '1' }
                                        ]}
                                    />
                                    </>
                                }
                                <View style={{ alignItems: 'center' }}>
                                    <TouchButton style={[styles.button, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%' }]} onPress={(this.state.isEdit) ? this.handleEditRichiamo : this.handleAddRichiamo}>
                                        {(this.state.loadingActive)
                                            ?
                                            <ActivityIndicator color='#fff' size='small' />
                                            :
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Salva</Text>
                                        }
                                    </TouchButton>
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </Modal>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#2484FF',
        overflow: 'hidden'
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : 300,
        marginTop: 45,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center',
        marginTop: 20
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    inputDate: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    buttonMore: {
        backgroundColor: '#0070FF',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        paddingHorizontal: 13,
        borderRadius: 18,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        minWidth: 100
    },
    listUser: {
        backgroundColor: '#f5f5f5',
        paddingHorizontal: 20,
        paddingVertical: 12,
        borderColor: '#ededed',
        borderTopWidth: 1,
    },
    containerIcon: {
        backgroundColor: '#fff',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        width: 55,
        height: 55,
        alignItems: 'center',
        justifyContent: 'center'
    },
    positionCricle: {
        position: 'absolute',
        bottom: - Dimensions.get('screen').width / 2,
        right: - Dimensions.get('screen').width / 2,
        zIndex: -1,
        overflow: 'hidden'
    },
    circle: {
        backgroundColor: '#8d4472',
        width: Dimensions.get('screen').width,
        height: Dimensions.get('screen').width,
        borderRadius: Dimensions.get('screen').width,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    responsiveCallButton: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '47%',
        marginTop: 0
    },
    responsiveCall: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '30%'
    },
    containerTitle: {
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: 15,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    headImage: {
        paddingBottom: 15,
        paddingHorizontal: 20,
        alignItems: 'center'
    },
    containerImage: {
        width: 100,
        height: 100,
        borderRadius: 15,
        backgroundColor: '#ffffff1c',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        zIndex: 2
    },
});