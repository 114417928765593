import React from 'react';
import { StyleSheet, Text, View, StatusBar, Dimensions, Image, ScrollView, Linking, Modal, Alert, TouchableOpacity as TouchButton , Platform, Switch, TouchableOpacity, TextInput } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { AntDesign, FontAwesome, Feather } from '@expo/vector-icons';
import { Table, Row, Rows, Cell, TableWrapper, Col } from 'react-native-table-component-2';
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RNPickerSelect from 'react-native-picker-select';
import * as Print from 'expo-print';
import { shareAsync } from 'expo-sharing';

export default class SoobPazientiScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pazienti: [],
            pazientiHistory: []
        }
    }

    componentDidMount() {
        this.getListofData()
    }

    getListofData = async () => {
        let token = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, '8Hsh-zjh83sdhy83Hbz')
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        fetch(`https://clinicautomation.it/app/opportunita_pazienti.php`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            },
            body: formData
        })
        .then((response) => response.json())
        .then((json) => {
            this.setState({ pazienti: json, pazientiHistory: json })
        }).catch((error) => {
            console.error(error);
        })
    }

    exportFile = () => {
        Alert.alert(
            "Esporta Opportunità",
            "Come le desideri esportare?",
            [
                { text: "Stampa", onPress: () => this.print() },
                { text: "Condividi", onPress: () => this.share() },
                { text: "Annulla", style: "cancel" }
            ]
        )
    }

    print = async () => {
        const htmlPrint = `
        <!DOCTYPE html>
        <html>
        <head>
        <style>
        ${(Platform.OS == 'android')
            &&
            '@page { margin: 20px; }'
        }
        
        main {
            break-inside: avoid;
        }
        
        @font-face {
          font-weight: 400;
          font-style:  normal;
          font-family: 'Circular-Loom';
        
          src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Book-cd7d2bcec649b1243839a15d5eb8f0a3.woff2') format('woff2');
        }
        
        @font-face {
          font-weight: 500;
          font-style:  normal;
          font-family: 'Circular-Loom';
        
          src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Medium-d74eac43c78bd5852478998ce63dceb3.woff2') format('woff2');
        }
        
        @font-face {
          font-weight: 700;
          font-style:  normal;
          font-family: 'Circular-Loom';
        
          src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Bold-83b8ceaf77f49c7cffa44107561909e4.woff2') format('woff2');
        }
        
        @font-face {
          font-weight: 900;
          font-style:  normal;
          font-family: 'Circular-Loom';
        
          src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Black-bf067ecb8aa777ceb6df7d72226febca.woff2') format('woff2');
        }
        
        table {
          font-family: arial, sans-serif;
          border-collapse: collapse;
          width: 100%;
        }
        
        td, th {
          border: 1px solid #dddddd;
          text-align: left;
          padding: 8px;
          font-family: 'Circular-Loom';
        }
        
        tr:nth-child(even) {
          background-color: #dddddd;
        }
        
        body {
            font-family: 'Circular-Loom';
        }
        
        #container {
          width: 30em;
          height: 20em;
          margin: 0;
          padding: 0;
        }
          .anychart-credits {
            display: none !important;
          }
        </style>
        </head>
        <body>
        <main style="height: ${(Platform.OS == 'android') ? '285mm' : '270mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="display: flex; justify-content: center; padding-top: 1em;">
                <h3>Opportunità Pazienti</h3>
            </div>
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 2em; padding-bottom: 2em">
                <table style="border-radius: 1em">
                <tr style="background-color: rgb(7, 149, 255)">
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Nome</th>
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Cognome</th>
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Email</th>
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Telefono</th>
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Valutazione</th>
                </tr>
                ${this.state.pazienti.map((item) => {
                    const country_code = JSON.parse(item.country_code)
                    return `<tr>
                                <td>${item.nome}</td>
                                <td>${item.cognome}</td>
                                <td>${item.email}</td>
                                <td>+${country_code.callingCode[0]}${item.telefono}</td>
                                <td>
                                    ${(item.valutazione != null && item.valutazione != '' && item.valutazione != 0 && item.valutazione != '0')
                                        ?
                                        (item.valutazione >= 5)
                                            ?
                                            '5 stelle'
                                            :
                                            `${item.valutazione} stelle`
                                        :
                                        ''
                                    }
                                </td>
                            </tr>`
                }).join('')}
                </table>
            </div>
        </main>
        </body>
        </html>
        `;
        await Print.printAsync({
          html: htmlPrint
        });
    }

    share = async () => {
        const htmlPrint = `
        <!DOCTYPE html>
        <html>
        <head>
        <style>
        ${(Platform.OS == 'android')
            &&
            '@page { margin: 20px; }'
        }
        
        main {
            break-inside: avoid;
        }
        
        @font-face {
          font-weight: 400;
          font-style:  normal;
          font-family: 'Circular-Loom';
        
          src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Book-cd7d2bcec649b1243839a15d5eb8f0a3.woff2') format('woff2');
        }
        
        @font-face {
          font-weight: 500;
          font-style:  normal;
          font-family: 'Circular-Loom';
        
          src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Medium-d74eac43c78bd5852478998ce63dceb3.woff2') format('woff2');
        }
        
        @font-face {
          font-weight: 700;
          font-style:  normal;
          font-family: 'Circular-Loom';
        
          src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Bold-83b8ceaf77f49c7cffa44107561909e4.woff2') format('woff2');
        }
        
        @font-face {
          font-weight: 900;
          font-style:  normal;
          font-family: 'Circular-Loom';
        
          src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Black-bf067ecb8aa777ceb6df7d72226febca.woff2') format('woff2');
        }
        
        table {
          font-family: arial, sans-serif;
          border-collapse: collapse;
          width: 100%;
        }
        
        td, th {
          border: 1px solid #dddddd;
          text-align: left;
          padding: 8px;
          font-family: 'Circular-Loom';
        }
        
        tr:nth-child(even) {
          background-color: #dddddd;
        }
        
        body {
            font-family: 'Circular-Loom';
        }
        
        #container {
          width: 30em;
          height: 20em;
          margin: 0;
          padding: 0;
        }
          .anychart-credits {
            display: none !important;
          }
        </style>
        </head>
        <body>
        <main style="height: ${(Platform.OS == 'android') ? '285mm' : '270mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="display: flex; justify-content: center; padding-top: 1em;">
                <h3>Opportunità Pazienti</h3>
            </div>
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 2em; padding-bottom: 2em">
                <table style="border-radius: 1em">
                <tr style="background-color: rgb(7, 149, 255)">
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Nome</th>
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Cognome</th>
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Email</th>
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Telefono</th>
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Valutazione</th>
                </tr>
                ${this.state.pazienti.map((item) => {
                    const country_code = JSON.parse(item.country_code)
                    return `<tr>
                                <td>${item.nome}</td>
                                <td>${item.cognome}</td>
                                <td>${item.email}</td>
                                <td>+${country_code.callingCode[0]}${item.telefono}</td>
                                <td>
                                    ${(item.valutazione != null && item.valutazione != '' && item.valutazione != 0 && item.valutazione != '0')
                                        ?
                                        (item.valutazione >= 5)
                                            ?
                                            '5 stelle'
                                            :
                                            `${item.valutazione} stelle`
                                        :
                                        ''
                                    }
                                </td>
                            </tr>`
                }).join('')}
                </table>
            </div>
        </main>
        </body>
        </html>
        `;
        const { uri } = await Print.printToFileAsync({
            html: htmlPrint
        })
        await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={{ backgroundColor: '#0070FF' }}>
                    <View style={styles.header}>
                        <TouchableOpacity style={styles.backButton} onPress={() => this.props.navigation.goBack()}>
                            <Feather name='arrow-left' color='#fff' size={23} />
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Opportunità</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.backButton} onPress={this.exportFile}>
                            <AntDesign name='export' color='#fff' size={23} />
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 7 }}>Esporta</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={{ backgroundColor: '#2484FF', paddingHorizontal: 20, paddingVertical: 15 }}>
                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, marginBottom: 10, color: '#FFF' }}>Opportunità di Business</Text>
                    <Text style={{ fontFamily: 'DM-Regular', fontSize: 14, color: '#FFF' }}>Lista dei pazienti ordinati in base alle loro opportunità di business.</Text>
                </View>
                <View style={styles.containerLink}>
                    <View style={[styles.head, { flexDirection: 'row', alignItems: 'center', paddingHorizontal: 0 }]}>
                        <View style={styles.widthTable}>
                            <Text style={[styles.text, { fontFamily: 'DM-Medium', color: '#fff', marginLeft: 10 }]}>Nome</Text>
                        </View>
                        <View style={styles.widthTable}>
                            <Text style={[styles.text, { fontFamily: 'DM-Medium', color: '#fff' }]}>Telefono</Text>
                        </View>
                        <View style={styles.widthTable}>
                            <Text style={[styles.text, { fontFamily: 'DM-Medium', color: '#fff', marginRight: 10 }]}>Valutazione</Text>
                        </View>
                    </View>
                    <ScrollView showsVerticalScrollIndicator={false}>
                        <View style={{ marginBottom: Dimensions.get('screen').height / 3 }}>
                            {this.state.pazienti.map((item) => {
                                const country_code = JSON.parse(item.country_code)
                                return(
                                    <View key={item.id} style={{ borderBottomWidth: 1, borderColor: '#ededed', backgroundColor: '#fff', flexDirection: 'row', alignItems: 'center' }}>
                                        <TouchableOpacity style={styles.widthRow} onPress={() => this.props.navigation.navigate('VisiteUtente', { idPaziente: item.id })}>
                                            <Text style={[styles.text, { fontFamily: 'DM-Regular', marginLeft: 10 }]}>{item.nome} {item.cognome}</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.widthRow} onPress={() => Linking.openURL(`tel:+${country_code.callingCode[0]}${item.telefono}`)}>
                                            <Text style={[styles.text, { fontFamily: 'DM-Regular', marginRight: 10, textDecorationStyle: 'solid', textDecorationLine: 'underline' }]}>+{country_code.callingCode[0]}{item.telefono}</Text>
                                        </TouchableOpacity>
                                        <View style={[styles.widthRow, { flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }]}>
                                            {(item.valutazione != null && item.valutazione != '' && item.valutazione != 0 && item.valutazione != '0')
                                                ?
                                                <>
                                                {(item.valutazione.split('.')[0] >= 1)
                                                    ?
                                                    <FontAwesome name='star' color='#e3e31e' size={21} />
                                                    :
                                                    (item.valutazione.split('.')[0] == 0)
                                                        ?
                                                        <FontAwesome name='star-half-o' color='#e3e31e' size={21} />
                                                        :
                                                        <FontAwesome name='star-o' color='#e3e31e' size={21} />
                                                }
                                                {(item.valutazione.split('.')[0] >= 2)
                                                    ?
                                                    <FontAwesome name='star' color='#e3e31e' size={21} />
                                                    :
                                                    (item.valutazione == 1.5)
                                                        ?
                                                        <FontAwesome name='star-half-o' color='#e3e31e' size={21} />
                                                        :
                                                        <FontAwesome name='star-o' color='#e3e31e' size={21} />
                                                }
                                                {(item.valutazione.split('.')[0] >= 3)
                                                    ?
                                                    <FontAwesome name='star' color='#e3e31e' size={21} />
                                                    :
                                                    (item.valutazione == 2.5)
                                                        ?
                                                        <FontAwesome name='star-half-o' color='#e3e31e' size={21} />
                                                        :
                                                        <FontAwesome name='star-o' color='#e3e31e' size={21} />
                                                }
                                                {(item.valutazione.split('.')[0] >= 4)
                                                    ?
                                                    <FontAwesome name='star' color='#e3e31e' size={21} />
                                                    :
                                                    (item.valutazione == 3.5)
                                                        ?
                                                        <FontAwesome name='star-half-o' color='#e3e31e' size={21} />
                                                        :
                                                        <FontAwesome name='star-o' color='#e3e31e' size={21} />
                                                }
                                                {(item.valutazione.split('.')[0] >= 5)
                                                    ?
                                                    <FontAwesome name='star' color='#e3e31e' size={21} />
                                                    :
                                                    (item.valutazione == 4.5)
                                                        ?
                                                        <FontAwesome name='star-half-o' color='#e3e31e' size={21} />
                                                        :
                                                        <FontAwesome name='star-o' color='#e3e31e' size={21} />
                                                }
                                                </>
                                                :
                                                <>
                                                <FontAwesome name='star-o' color='#e3e31e' size={21} />
                                                <FontAwesome name='star-o' color='#e3e31e' size={21} />
                                                <FontAwesome name='star-o' color='#e3e31e' size={21} />
                                                <FontAwesome name='star-o' color='#e3e31e' size={21} />
                                                <FontAwesome name='star-o' color='#e3e31e' size={21} />
                                                </>
                                            }
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    </ScrollView>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#edf7ff'
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: (Dimensions.get('screen').width - 50) - ((Dimensions.get('screen').width / 1.3) - 50),
        height: 45,
        paddingHorizontal: 10,
        paddingVertical: 6,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    header: {
        paddingHorizontal: 20,
        paddingTop: 10 + Constants.statusBarHeight,
        paddingBottom: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    containerLink: {
        paddingBottom: 30
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    head: {
        height: 50,
        backgroundColor: '#0070FF',
        paddingHorizontal: 5
    },
    text: { 
        marginVertical: 6 
    },
    input: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: (Dimensions.get('screen').width / 1.3) - 50,
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 45,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 15,
        marginRight: 10
    },
    inputSelect: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    widthTable: {
        width: Dimensions.get('screen').width / 3
    },
    widthRow: {
        height: 40,
        width: Dimensions.get('screen').width / 3,
        justifyContent: 'center'
    },
    backButtonModal: {
        backgroundColor: '#f5f5f5',
        width: 50,
        height: 50,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonModal: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
});