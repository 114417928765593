import React from 'react';
import { WebView } from 'react-native-webview';

export default class Chart extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <WebView
                style={{ width: this.props.width, height: this.props.height }}
                scrollEnabled={false}
                onLoad={this.props.onLoadFunction}
                source={{
                    html: `
                    <html>
                        <head>
                            <meta name="viewport" content="user-scalable=no" />
                            <script src="https://cdn.anychart.com/releases/8.7.1/js/anychart-core.min.js"></script>
                            <script src="https://cdn.anychart.com/releases/8.7.1/js/anychart-radar.min.js"></script>
                            <style type="text/css">
                                    html, body, #container {
                                        width: 100%;
                                        height: 100%;
                                        margin: 0;
                                        padding: 0;
                                    }
                                    .anychart-credits {
                                        display: none !important;
                                    }
                                    text {
                                        font-size: 20px !important;
                                    }
                            </style>
                        </head>
                        <body>
                            <div id="container"></div>
                            <script>
                                anychart.onDocumentReady(function () {
                                    // our data from bulbapedia
                                    var data1 = [
                                    {x: "FORCAZIONI", value: ${
                                        ((this.props.forcazioniSuperiore.length + this.props.forcazioniInferiore.length) == 16)
                                        ?
                                        10
                                        :
                                        ((this.props.forcazioniSuperiore.length + this.props.forcazioniInferiore.length) > 0 && (this.props.forcazioniSuperiore.length + this.props.forcazioniInferiore.length) <= 4)
                                        ?
                                        2
                                        :
                                        ((this.props.forcazioniSuperiore.length + this.props.forcazioniInferiore.length) > 4 && (this.props.forcazioniSuperiore.length + this.props.forcazioniInferiore.length) <= 8)
                                        ?
                                        4
                                        :
                                        ((this.props.forcazioniSuperiore.length + this.props.forcazioniInferiore.length) > 8 && (this.props.forcazioniSuperiore.length + this.props.forcazioniInferiore.length) <= 12)
                                        ?
                                        6
                                        :
                                        ((this.props.forcazioniSuperiore.length + this.props.forcazioniInferiore.length) > 12 && (this.props.forcazioniSuperiore.length + this.props.forcazioniInferiore.length) < 16)
                                        ?
                                        8
                                        :
                                        ((this.props.forcazioniSuperiore.length + this.props.forcazioniInferiore.length) == 0)
                                        ?
                                        0
                                        :
                                        0
                                    }},
                                    {x: "TASCHE", value: ${
                                        ((this.props.tascheSuperiore.length + this.props.tascheInferiore.length) == 32)
                                        ?
                                        10
                                        :
                                        ((this.props.tascheSuperiore.length + this.props.tascheInferiore.length) > 0 && (this.props.tascheSuperiore.length + this.props.tascheInferiore.length) <= 8)
                                        ?
                                        3
                                        :
                                        ((this.props.tascheSuperiore.length + this.props.tascheInferiore.length) > 8 && (this.props.tascheSuperiore.length + this.props.tascheInferiore.length) <= 16)
                                        ?
                                        6
                                        :
                                        ((this.props.tascheSuperiore.length + this.props.tascheInferiore.length) > 16 && (this.props.tascheSuperiore.length + this.props.tascheInferiore.length) <= 24)
                                        ?
                                        8
                                        :
                                        ((this.props.tascheSuperiore.length + this.props.tascheInferiore.length) > 24 && (this.props.tascheSuperiore.length + this.props.tascheInferiore.length) < 32)
                                        ?
                                        9
                                        :
                                        ((this.props.tascheSuperiore.length + this.props.tascheInferiore.length) == 0)
                                        ?
                                        0
                                        :
                                        0
                                    }},
                                    {x: "DENTI MOBILI", value: ${
                                        (this.props.dentiMobili)
                                        ?
                                        9
                                        :
                                        0
                                    }},
                                    {x: "FUMO", value: ${
                                        (this.props.fumo == null)
                                        ?
                                        0
                                        :
                                        (this.props.fumo == 'No')
                                        ?
                                        0
                                        :
                                        (this.props.fumo == 'Fino a 10')
                                        ?
                                        5
                                        :
                                        (this.props.fumo == '20')
                                        ?
                                        8
                                        :
                                        (this.props.fumo == 'Piu')
                                        ?
                                        10
                                        :
                                        0
                                    }},
                                    {x: "MALATTIE SISTEMICHE", value: ${
                                        (this.props.malattieSistemiche > 10)
                                        ?
                                        10
                                        :
                                        this.props.malattieSistemiche
                                    }},
                                    {x: "DENTI MANCANTI", value: ${
                                        ((this.props.dentiMancantiSuperiore.length + this.props.dentiMancantiInferiore.length) == 32)
                                        ?
                                        10
                                        :
                                        ((this.props.dentiMancantiSuperiore.length + this.props.dentiMancantiInferiore.length) > 0 && (this.props.dentiMancantiSuperiore.length + this.props.dentiMancantiInferiore.length) <= 8)
                                        ?
                                        3
                                        :
                                        ((this.props.dentiMancantiSuperiore.length + this.props.dentiMancantiInferiore.length) > 8 && (this.props.dentiMancantiSuperiore.length + this.props.dentiMancantiInferiore.length) <= 16)
                                        ?
                                        6
                                        :
                                        ((this.props.dentiMancantiSuperiore.length + this.props.dentiMancantiInferiore.length) > 16 && (this.props.dentiMancantiSuperiore.length + this.props.dentiMancantiInferiore.length) <= 24)
                                        ?
                                        8
                                        :
                                        ((this.props.dentiMancantiSuperiore.length + this.props.dentiMancantiInferiore.length) > 24 && (this.props.dentiMancantiSuperiore.length + this.props.dentiMancantiInferiore.length) < 32)
                                        ?
                                        9
                                        :
                                        ((this.props.dentiMancantiSuperiore.length + this.props.dentiMancantiInferiore.length) == 0)
                                        ?
                                        0
                                        :
                                        0
                                    }},
                                    {x: "SANGUINAMENTO", value: ${
                                        (this.props.sanguinamentoGengive == null)
                                        ?
                                        0
                                        :
                                        (this.props.sanguinamentoGengive == '0')
                                        ?
                                        0
                                        :
                                        (this.props.sanguinamentoGengive == '1')
                                        ?
                                        4
                                        :
                                        (this.props.sanguinamentoGengive == '2')
                                        ?
                                        7
                                        :
                                        (this.props.sanguinamentoGengive == '3')
                                        ?
                                        10
                                        :
                                        0
                                    }},
                                    {x: "IGENE ORALE", value: ${
                                        (this.props.igeneOrale == null)
                                        ?
                                        0
                                        :
                                        (this.props.igeneOrale == 'Buona')
                                        ?
                                        0
                                        :
                                        (this.props.igeneOrale == 'Sufficente')
                                        ?
                                        5
                                        :
                                        (this.props.igeneOrale == 'Scarsa')
                                        ?
                                        10
                                        :
                                        0
                                    }},
                                    ];
                                
                                    // create radar chart
                                    var chart = anychart.radar();
                                    // set chart yScale settings
                                    chart.yScale()
                                    .minimum(0)
                                    .maximum(10)
                                    .ticks({'interval':1});
                                
                                    // create first series
                                    chart.area(data1).name('Rischio').markers(true).fill("#3782fa", 0.3).stroke("#3782fa")
                                
                                    // set container id for the chart
                                    chart.container('container');
                                    // initiate chart drawing
                                    chart.draw();
                                });
                            </script>
                        </body>
                    </html>`
                }}
            />
        )
    }
}