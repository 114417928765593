import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Dimensions, Alert, TouchableOpacity, TextInput } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather } from '@expo/vector-icons';
import PhoneInput from "react-native-phone-number-input";
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class EditProfiloScreen extends React.Component {
    constructor(props) {
        super(props)
        this.phoneRef = React.createRef()
        this.state = {
            loading: false,
            loading2: false,
            nome: '',
            cognome: '',
            email: '',
            password: '',
            newPassword: '',
            telefono: '',
            telefonoCaricato: false,
            country: {
                "callingCode": [
                    "39",
                ],
                "cca2": "IT",
                "currency": [
                    "EUR",
                ],
                "flag": "flag-it",
                "name": "Italy",
                "region": "Europe",
                "subregion": "Southern Europe"
            }
        }
    }

    async componentDidMount() {
        this.checkAuth()
        const nome = await AsyncStorage.getItem('nome')
        const cognome = await AsyncStorage.getItem('cognome')
        const email = await AsyncStorage.getItem('email')
        const telefono = await AsyncStorage.getItem('telefono')
        const country_code = await AsyncStorage.getItem('country_code')
        this.setState({ nome, cognome, email, telefono, country: JSON.parse(country_code), telefonoCaricato: true })
    }

    checkAuth = async () => {
        const id = await AsyncStorage.getItem('id')
        if (id != null && id != undefined && id != '') {

        } else {
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'Login' }],
            })
        }
    }

    editProfilo = async () => {
        this.setState({ loading: true })
        if (this.state.email != '' && this.state.nome != '' && this.state.cognome != '' && this.state.telefono != '') {
            const isValidaNumber = this.phoneRef.current.isValidNumber(this.state.telefono)
            if (isValidaNumber == true) {
                let id = await AsyncStorage.getItem('id')
                const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
                let formData = new FormData();
                formData.append('token', token);
                formData.append('email', this.state.email);
                formData.append('nome', this.state.nome);
                formData.append('cognome', this.state.cognome);
                formData.append('telefono', this.state.telefono);
                formData.append('country_code', JSON.stringify(this.state.country))
                formData.append('id_user', id);
                axios.post('https://clinicautomation.it/app/update_profilo.php', formData, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(async (response) => {
                    const json = response.data;
                    if (json.code == true) {
                        await AsyncStorage.setItem('nome', this.state.nome)
                        await AsyncStorage.setItem('cognome', this.state.cognome)
                        await AsyncStorage.setItem('email', this.state.email)
                        await AsyncStorage.setItem('telefono', this.state.telefono)
                        await AsyncStorage.setItem('country_code', JSON.stringify(this.state.country))
                        this.setState({ loading: false })
                        alert(`Successo! ${json.message}`)
                    } else {
                        this.setState({ loading: false })
                        alert(`Attenzione! ${json.message}`)
                    }
                }).catch((error) => {
                    this.setState({ loading: false })
                    console.error(error);
                })
            } else {
                this.setState({ loading: false })
                alert(`Attenzione! Il numero di telefono inserito non è valido`)
            }
        } else {
            this.setState({ loading: false })
            alert(`Attenzione! Compila tutti i campi`)
        }
    }

    editPassword = async () => {
        this.setState({ loading2: true })
        if (this.state.password != '' && this.state.newPassword != '') {
            let id = await AsyncStorage.getItem('id')
            const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let password = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, this.state.password)
            let nuova_password = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, this.state.newPassword)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('password', password);
            formData.append('nuova_password', nuova_password);
            formData.append('id_user', id);
            axios.post('https://clinicautomation.it/app/update_password_profilo.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    await AsyncStorage.setItem('password', nuova_password)
                    this.setState({ loading2: false, password: '', newPassword: '' })
                    alert(`Successo! ${json.message}`)
                } else {
                    this.setState({ loading2: false })
                    alert(`Attenzione! ${json.message}`)
                }
            }).catch((error) => {
                this.setState({ loading2: false })
                console.error(error);
            })
        } else {
            this.setState({ loading2: false })
            alert(`Attenzione! Compila tutti i campi`)
        }
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('App'); // Schermata di fallback
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                    </TouchableOpacity>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={[styles.containerCenter, { marginBottom: 20 }]}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, textAlign: 'center', marginBottom: 25 }}>Modifica Profilo</Text>
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Nome'
                            value={this.state.nome}
                            onChangeText={(nome) => this.setState({ nome })}
                        />
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Cognome'
                            value={this.state.cognome}
                            onChangeText={(cognome) => this.setState({ cognome })}
                        />
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Indirizzo email'
                            value={this.state.email}
                            onChangeText={(email) => this.setState({ email: email.toLowerCase().trim() })}
                            keyboardType='email-address'
                            autoCapitalize='none'
                        />
                        {(this.state.telefonoCaricato)
                            &&
                            <PhoneInput
                                ref={this.phoneRef}
                                defaultCode={this.state.country.cca2}
                                layout="first"
                                withDarkTheme={false}
                                withShadow={false}
                                placeholder='Numero di cellulare'
                                containerStyle={{ width: '100%', borderTopRightRadius: 10, borderBottomRightRadius: 10, marginBottom: 20 }}
                                textContainerStyle={{ borderTopRightRadius: 10, borderBottomRightRadius: 10, paddingVertical: 10, height: 55, backgroundColor: '#f5f5f5' }}
                                textInputStyle={{ fontFamily: 'DM-Regular', fontSize: 16 }}
                                codeTextStyle={{ fontFamily: 'DM-Regular', fontSize: 16 }}
                                flagButtonStyle={{ backgroundColor: '#e8e8e8', borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
                                filterProps={{ placeholder: 'Cerca nazione o prefisso' }}
                                value={this.state.telefono}
                                defaultValue={this.state.telefono}
                                onChangeText={(telefono) => this.setState({ telefono })}
                                onChangeCountry={(country) => this.setState({ country })}
                            />
                        }
                        <View style={{ alignItems: 'center' }}>
                            <TouchableOpacity style={[styles.button, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%' }]} onPress={this.editProfilo}>
                                {(this.state.loading)
                                    ?
                                    <ActivityIndicator color='#fff' size='small' />
                                    :
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Modifica</Text>
                                }
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={[styles.containerCenter, { backgroundColor: '#2484FF', paddingTop: 50, marginBottom: 0, paddingBottom: Dimensions.get('screen').height / 3 }]}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, textAlign: 'center', marginBottom: 25, color: '#FFF' }}>Modifica Password</Text>
                        <TextInput
                            style={[styles.input, { backgroundColor: '#fff' }]}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Vecchia password'
                            value={this.state.password}
                            onChangeText={(password) => this.setState({ password: password.trim() })}
                            secureTextEntry={true}
                        />
                        <TextInput
                            style={[styles.input, { backgroundColor: '#fff' }]}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Nuova password'
                            value={this.state.newPassword}
                            onChangeText={(newPassword) => this.setState({ newPassword: newPassword.trim() })}
                            secureTextEntry={true}
                        />
                        <View style={{ alignItems: 'center' }}>
                            <TouchableOpacity style={[styles.button, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%', backgroundColor: '#fff' }]} onPress={this.editPassword}>
                                {(this.state.loading2)
                                    ?
                                    <ActivityIndicator color='#0070FF' size='small' />
                                    :
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#0070FF' }}>Modifica password</Text>
                                }
                            </TouchableOpacity>
                        </View>
                    </View>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : 300,
        marginTop: 45,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'flex-start',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 50,
        height: 50,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    }
});