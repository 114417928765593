import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Dimensions, Alert, Platform, TouchableOpacity, TextInput } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather, AntDesign, FontAwesome } from '@expo/vector-icons';
import PhoneInput from "react-native-phone-number-input";
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Application from 'expo-application';

export default class MessageScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customerId: null,
            subscriptionId: null,
            pagamento: null,
            devices: [],
            isProva: null
        }
    }

    componentDidMount() {
        this._unsubscribe = this.props.navigation.addListener('focus', () => {
            this.getBilling()
        });
        this.getBilling()
    }

    componentWillUnmount() {
        this._unsubscribe
    }

    getBilling = async () => {
        const id = await AsyncStorage.getItem('id')
        let token = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, '8Hsh-zjh83sdhy83Hbz')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id', id);
        fetch(`https://clinicautomation.it/app/get_subscription_detail.php`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            },
            body: formData
        })
        .then((response) => response.json())
        .then(async (json) => {
            if (json.code == true) {
                this.setState({ customerId: json.customer_id, subscriptionId: json.subscription_id, pagamento: json.pagamento, isProva: json.is_prova, devices: JSON.parse(json.devices) })
            } else {
                Alert.alert(
                    "Attenzione",
                    json.message,
                    [
                        { text: "OK" }
                    ]
                );
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    requestLogout = () => {
        Alert.alert(
            "Attenzione",
            "Sei sicuro di voler uscire?",
            [
              {
                text: "Annulla",
                style: "cancel"
              },
              { text: "Esci", onPress: () => this.logout() }
            ]
        );
    }

    logout = async () => {
        const id = await AsyncStorage.getItem('id')
        let devideId;
        if (Platform.OS == 'android') {
            devideId = Application.androidId;
        } else {
            devideId = await Application.getIosIdForVendorAsync()
        }
        let arrayDevices = this.state.devices;
        if (arrayDevices.includes(devideId)) {
            let token = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, '8Hsh-zjh83sdhy83Hbz')
            arrayDevices = arrayDevices.filter(e => e !== devideId)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_user', id);
            formData.append('devices', JSON.stringify(arrayDevices))
            fetch(`https://clinicautomation.it/app/not_active.php`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                },
                body: formData
            })
            .then((response) => response.json())
            .then(async (json) => {
                await AsyncStorage.removeItem('id')
                await AsyncStorage.removeItem('nome')
                await AsyncStorage.removeItem('cognome')
                await AsyncStorage.removeItem('data_scadenza')
                await AsyncStorage.removeItem('email')
                await AsyncStorage.removeItem('password')
                await AsyncStorage.removeItem('telefono')
                await AsyncStorage.removeItem('data_inizio')
                await AsyncStorage.removeItem('is_prova')
                await AsyncStorage.removeItem('periodo_prova')
                await AsyncStorage.removeItem('abbonamento_attivo')
                await AsyncStorage.removeItem('ragione_sociale')
                await AsyncStorage.removeItem('nome_studio')
                await AsyncStorage.removeItem('image')
                await AsyncStorage.removeItem('via')
                await AsyncStorage.removeItem('citta')
                await AsyncStorage.removeItem('piva')
                this.props.navigation.navigate('Auth')
            }).catch((error) => {
                console.error(error);
            })
        } else {
            await AsyncStorage.removeItem('id')
            await AsyncStorage.removeItem('nome')
            await AsyncStorage.removeItem('cognome')
            await AsyncStorage.removeItem('data_scadenza')
            await AsyncStorage.removeItem('email')
            await AsyncStorage.removeItem('password')
            await AsyncStorage.removeItem('telefono')
            await AsyncStorage.removeItem('data_inizio')
            await AsyncStorage.removeItem('is_prova')
            await AsyncStorage.removeItem('periodo_prova')
            await AsyncStorage.removeItem('abbonamento_attivo')
            await AsyncStorage.removeItem('ragione_sociale')
            await AsyncStorage.removeItem('nome_studio')
            await AsyncStorage.removeItem('image')
            await AsyncStorage.removeItem('via')
            await AsyncStorage.removeItem('citta')
            await AsyncStorage.removeItem('piva')
            this.props.navigation.navigate('Auth')
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <View style={styles.backButton}>
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff' }}>Abbonamento Richiesto</Text>
                    </View>
                    <TouchableOpacity style={styles.backButtonSecond} onPress={this.requestLogout}>
                        <AntDesign name='poweroff' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Esci</Text>
                    </TouchableOpacity>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={styles.containerCenter}>
                        <View style={{
                            backgroundColor: '#ffc0a1',
                            borderRadius: 17,
                            padding: 20,
                            marginBottom: 25
                        }}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 20, textAlign: 'center' }}>Attenzione!</Text>
                        </View>
                        {(this.state.isProva == 1)
                            ?
                            <>
                                <Text style={{ fontFamily: 'DM-Regular', fontSize: 17, marginBottom: 20 }}><Text style={{ fontFamily: 'DM-Medium' }}>Il tuo periodo di prova è terminato.</Text>{'\n'}Per continuare ad utilizzare l'app devi procedere con il pagamento dell'iscrizione.</Text>
                                <TouchableOpacity style={styles.button} onPress={() => this.props.navigation.navigate('SalePage2')}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Acquista</Text>
                                </TouchableOpacity>
                            </>
                            :
                            <>
                                <Text style={{ fontFamily: 'DM-Regular', fontSize: 17, marginBottom: 20 }}><Text style={{ fontFamily: 'DM-Medium' }}>Il metodo di pagamento è stato disattivato.</Text>{'\n'}Modifica il tuo metodo di pagamento per riattivare l'abbonamento.</Text>
                                {(this.state.pagamento == 'Stripe')
                                    ?
                                    <>
                                    <TouchableOpacity style={[styles.button, { marginBottom: 20 }]} onPress={() => this.props.navigation.navigate('Billing2', { customer_id: this.state.customerId })}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Gestisci abbonamento</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.button} onPress={() => this.props.navigation.navigate('App')}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Torna alla Home</Text>
                                    </TouchableOpacity>
                                    </>
                                    :
                                    (this.state.pagamento == 'PayPal')
                                        ?
                                        <>
                                        <TouchableOpacity style={styles.button} onPress={() => this.props.navigation.navigate('PayPalBilling', { subscription: this.state.subscriptionId })}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Gestisci abbonamento</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.button} onPress={() => this.props.navigation.navigate('App')}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Torna alla Home</Text>
                                        </TouchableOpacity>
                                        </>
                                        :
                                        <TouchableOpacity style={styles.button} onPress={() => this.props.navigation.navigate('SalePage2')}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Acquista</Text>
                                        </TouchableOpacity>
                                }
                            </>
                        }
                    </View>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: 30,
        marginTop: 25,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0795FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    header: {
        backgroundColor: '#0795FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#36a9ff',
        width: 'auto',
        paddingHorizontal: 20,
        height: 50,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    typeOne: {
        backgroundColor: '#f5f5f5',
        paddingHorizontal: 25,
        paddingVertical: 20,
        borderTopLeftRadius: 17,
        borderTopRightRadius: 17,
        borderColor: 'lightgrey',
        borderBottomWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    typeTwo: {
        backgroundColor: '#f5f5f5',
        paddingHorizontal: 25,
        paddingVertical: 20,
        borderBottomLeftRadius: 17,
        borderBottomRightRadius: 17,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: 25
    },
    backButtonSecond: {
        backgroundColor: '#36a9ff',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    }
});